import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  Typography, Grid2, Stack,   
  IconButton,
  Tooltip,
  Icon
} from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import ContactIcon from '@mui/icons-material/ContactPhone';
import PlaceIcon from '@mui/icons-material/Place';
import MarkUnreadChatAltOutlinedIcon from '@mui/icons-material/MarkUnreadChatAltOutlined';

import { subDays, startOfDay, endOfDay } from 'date-fns';

import withStyles from '@mui/styles/withStyles';

import { withRouter } from 'utils';

import {
  LoadingView,
} from 'components';
import { StoreUtil} from 'doctivity-shared/utils';

import { loadAnalytics } from 'store/actions/analyticsActions';

const styles = (theme) => ({
  filters: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  spacer: {
    flex: 1,
  },
  menuButton: {
    flex: 1,
    padding: 0
  },
  menuIcon: {
    fontSize: 40,
    fontWeight: 200,
    color: 'rgba(2, 227, 157, 0.6)'

  },
  filterSpace: {
    width: 4,
  },
  rangeControl: {
    minWidth: 90,
  },
  userFilter: {
    minWidth: 270,
  },
  typeControl: {
    minWidth: 260,
  },
  hamberder: {
    display: 'flex',
    flexGrow: 0,
    justifyContent: 'flex-start',
  },
  headlinesCard: {
    borderWidth: '0', 
    boxShadow: '0 !important', 
    backgroundColor: 'transparent'
  },
  toolTip: {
    fontSize: 40,
    fontWeight: 200,
    color: 'lightgray',
    textAlign: 'center'
  },
  infoTipIcon: {
    position: 'relative',
    top: -4,
    marginLeft: theme.spacing(),
    fontSize: 14,
    fontWeight: 200,
    color: '#999999',
  },
  infoTip: {
    fontSize: 13,
    fontWeight: 200,
  },
  headlines: {
    fontSize: 12,
    color: '#8E8E8E',
    lineHeight: 1.5,
    marginTop: '0.5em',
  },
});


class ProviderCommentsHeadlines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      encounterType: 'All',
      startDate: startOfDay(subDays(new Date(), 365)),
      endDate: endOfDay(new Date()),
      query: {
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    if (StoreUtil.needsLoadNoCache(this.props.headlinesReport)) {
      this.fetchData();
      this.fetchOutreach();
    }
  }

  componentDidUpdate(prevProps) {
    // anytime the client changes
    if (this.props.app.selectedClient !== prevProps.app.selectedClient 
      || this.props.encounterType !== prevProps.encounterType
      || this.props.filterUser !== prevProps.filterUser
      || this.props.dateRange.startDate !== prevProps.dateRange.startDate
      || this.props.dateRange.endDate !== prevProps.dateRange.endDate) {
      this.fetchData();
      this.fetchOutreach();
    } else if (StoreUtil.needsLoadMediumCache(this.props.headlinesReport)) {
      this.fetchData();
      this.fetchOutreach();
    }
  }

  fetchData() {
    //const { query } = this.state;
    const { dispatch, clientId, encounterType } = this.props;

    let filter = {
      client_id: clientId,
      encounter_type_id: encounterType,
      user_id: this.props.filterUser?.id,
      comment_date: {
        //$gte: DateUtil.formatDateTimeFromDB(this.props.dateRange.startDate),
        //$lte: DateUtil.formatDateTimeFromDB(this.props.dateRange.endDate),

        $gte: this.props.dateRange.startDate.toISOString(),
        $lte: this.props.dateRange.endDate.toISOString(),
      },
    }
    
    if (encounterType !== 'All') {
      filter.encounter_type_id = encounterType;
    }

    dispatch(
      loadAnalytics({
        type: 'PROVIDERS_HEADLINES_COUNT',
        filter: filter,
        opts: {
          limit: 100,
          offset: 0,
          //order: query.order,
        },
      })
    );
  }

  fetchOutreach() {
    //const { query } = this.state;
    const { dispatch, clientId, encounterType } = this.props;

    let filter = {
      client_id: clientId,
      encounter_type_id: encounterType,
      user_id: this.props.filterUser?.id,
      comment_date: {
        //$gte: DateUtil.formatDateTimeFromDB(this.props.dateRange.startDate),
        //$lte: DateUtil.formatDateTimeFromDB(this.props.dateRange.endDate),

        $gte: this.props.dateRange.startDate.toISOString(),
        $lte: this.props.dateRange.endDate.toISOString(),
      },
    }
    
    if (encounterType !== 'All') {
      filter.encounter_type_id = encounterType;
    }

    dispatch(
      loadAnalytics({
        type: 'PROVIDERS_OUTREACH_COUNT',
        filter: filter,
        opts: {
          limit: 100,
          offset: 0,
          //order: query.order,
        },
      })
    );
  }

  

  render() {
    const { headlinesReport, outreachReport, classes} = this.props;

    if (!StoreUtil.isLoaded(headlinesReport) | !StoreUtil.isLoaded(outreachReport)) {
      return <LoadingView />;
    }

    let newentities = 0;
    let oldentities = 0;
    let newentitiescount = '';
    let newentitiescolor = 'primary';
    let newentitiesdisplay = ''

    let uniqueproviders = '';
    let uniquecontacts = '';
    let uniquelocations = '';

    let uniqueproviderscount = '';
    let uniquecontactscount = '';
    let uniquelocationscount = '';
    
    let uniqueproviderscolor = 'primary';
    let uniquecontactscolor = 'primary';
    let uniquelocationscolor = 'primary';


    if (headlinesReport.data[1]) {
      if (headlinesReport.data[1].uniqueproviders != 0) {
        uniqueproviders = ((headlinesReport.data[0].uniqueproviders-headlinesReport.data[1].uniqueproviders)/headlinesReport.data[1].uniqueproviders*100).toFixed(0)
        uniqueproviderscolor = uniqueproviders >= 0 ? 'black' : 'red';
        uniqueproviders = (uniqueproviders > 0 ? '+' : '') + uniqueproviders + '%'
      }
      uniqueproviderscount = `${headlinesReport.data[0].uniqueproviders}`
      if (headlinesReport.data[1].uniquecontacts != 0) {
        uniquecontacts = ((headlinesReport.data[0].uniquecontacts-headlinesReport.data[1].uniquecontacts)/headlinesReport.data[1].uniquecontacts*100).toFixed(0)
        uniquecontactscolor = uniquecontacts >= 0 ? 'black' : 'red';
        uniquecontacts = (uniquecontacts > 0 ? '+' : '') + uniquecontacts + '%'
      }
      uniquecontactscount = `${headlinesReport.data[0].uniquecontacts}`
      if (headlinesReport.data[1].uniquelocations != 0) {
        uniquelocations = ((headlinesReport.data[0].uniquelocations-headlinesReport.data[1].uniquelocations)/headlinesReport.data[1].uniquelocations*100).toFixed(0)
        uniquelocationscolor = uniquelocations >= 0 ? 'black' : 'red';
        uniquelocations = (uniquelocations > 0 ? '+' : '') + uniquelocations + '%'
      }
      uniquelocationscount = `${headlinesReport.data[0].uniquelocations}`
    }

    if (outreachReport.data) {
      newentities = outreachReport.data[0] + outreachReport.data[1] + outreachReport.data[2];
      oldentities = outreachReport.data[3] + outreachReport.data[4] + outreachReport.data[5];

      if (oldentities != 0) {
        newentitiesdisplay = ((newentities-oldentities)/oldentities*100).toFixed(0)
        newentitiescolor = newentitiesdisplay >= 0 ? 'black' : 'red';
        newentitiesdisplay = (newentitiesdisplay > 0 ? '+' : '') + newentitiesdisplay + '%'
      }
      newentitiescount = `${newentities}`
    }

    return (

      
      <Grid2 container spacing={{ xs: 1, md: 2 }} columns={{ xs: 1, sm: 2, md: 4 }} marginBottom={'50px'} marginTop={'30px'}>
        <Grid2 size={{ xs: 1, sm: 1, md: 1 }} columns={ 1 } justifyContent='left' alignItems='left'>
          <Stack direction='row' spacing={2} justifyContent='left' alignItems='left'>
            <Typography className={classes.headlines}>
              NEW OUTREACH
            </Typography>
            <Tooltip
              placement='bottom-start'
              title={(
                <Typography className={classes.infoTip}>
                  A count of Providers, Contacts, and Locations that have never been on a previous Activity Note in Doctivity.
                </Typography>
              )}
            >
            <Icon className={classes.infoTipIcon}>info_outlines</Icon>
            </Tooltip>
          </Stack>
          
          <Stack direction='row' spacing={2} justifyContent='left' alignItems='center' >
            <div className={classes.hamberder}>
              <IconButton
                className={classes.menuButton}
                size='large'
              >
                <Tooltip>
                  <MarkUnreadChatAltOutlinedIcon color='primary' className={classes.menuIcon} />
                </Tooltip>
              </IconButton>
            </div>
            <Typography component='h2' variant='h5' color='black' textAlign='left'>
              {newentitiescount}    
            </Typography>
            <Typography fontWeight='300' fontSize='.8rem' color={newentitiescolor} textAlign='left' style={{ paddingTop: '5px', marginLeft: '5px'}}>
              {newentitiesdisplay}                    
            </Typography>

          </Stack>

        </Grid2>

        <Grid2 size={{ xs: 1, sm: 1, md: 1 }} columns={ 1 } justifyContent='left' alignItems='left'>
          <Stack direction='row' spacing={2} justifyContent='left' alignItems='center' >  
            <Typography className={classes.headlines}>
              UNIQUE TAGGED PROVIDERS
            </Typography>
            <Tooltip
              placement='bottom-start'
              title={(
                <Typography className={classes.infoTip}>
                  A count of the unique providers on Activity Notes within the selected time period.
                </Typography>
              )}
            >
              <Icon className={classes.infoTipIcon}>info_outlined</Icon>
            </Tooltip>
          </Stack>
          <Stack direction='row' spacing={2} justifyContent='left' alignItems='center' >
            <div className={classes.hamberder}>
              <IconButton
                className={classes.menuButton}
                size='large'
              >
                <Tooltip>
                  <PeopleIcon color='primary' className={classes.menuIcon} />
                </Tooltip>
              </IconButton>
            </div>            
            <Typography component='h2' variant='h5' color='black' textAlign='left'>
              {uniqueproviderscount}    
            </Typography>
            <Typography fontWeight='300' fontSize='.8rem' color={uniqueproviderscolor} textAlign='left' style={{ paddingTop: '5px', marginLeft: '5px'}}>
              {uniqueproviders}                    
            </Typography>
          </Stack>
        </Grid2>


        <Grid2 size={{ xs: 1, sm: 1, md: 1 }} columns={ 1 } justifyContent='left' alignItems='left'>
          <Stack direction='row' spacing={2} justifyContent='left' alignItems='center' >
            <Typography className={classes.headlines}>
              UNIQUE TAGGED CONTACTS
            </Typography>
            <Tooltip
              placement='bottom-start'
              title={(
                <Typography className={classes.infoTip}>
                  A count of the unique contacts on Activity Notes within the selected time period.
                </Typography>
              )}
            >
            <Icon className={classes.infoTipIcon}>info_outlined</Icon>
            </Tooltip>
          </Stack>
          <Stack direction='row' spacing={2} justifyContent='left' alignItems='center' >
          <div className={classes.hamberder}>
              <IconButton
                className={classes.menuButton}
                size='large'
              >
                <Tooltip>
                  <ContactIcon color='primary' className={classes.menuIcon} />
                </Tooltip>
              </IconButton>
            </div>
            <Typography component='h2' variant='h5' color='black' textAlign='left'>
              {uniquecontactscount}    
            </Typography>
            <Typography fontWeight='300' fontSize='.8rem' color={uniquecontactscolor} textAlign='left' style={{ paddingTop: '5px', marginLeft: '5px'}}>
              {uniquecontacts}                    
            </Typography>
          </Stack>
        </Grid2>

        <Grid2 size={{ xs: 1, sm: 1, md: 1 }} columns={ 1 } justifyContent='left' alignItems='left'>
          <Stack direction='row' spacing={2} justifyContent='left' alignItems='center' >
          <Typography className={classes.headlines}>
              UNIQUE TAGGED LOCATIONS
            </Typography>
            <Tooltip
              placement='bottom-start'
              title={(
                <Typography className={classes.infoTip}>
                  A count of the unique locations on Activity Notes within the selected time period.
                </Typography>
              )}
            >
              <Icon className={classes.infoTipIcon}>info_outlined</Icon>
            </Tooltip>
          </Stack>
          <Stack direction='row' spacing={2} justifyContent='left' alignItems='center' >
            <div className={classes.hamberder}>
              <IconButton
                className={classes.menuButton}
                size='large'
              >
                <Tooltip>
                  <PlaceIcon color='primary' className={classes.menuIcon} />
                </Tooltip>
              </IconButton>
            </div>
            <Typography component='h2' variant='h5' color='black' textAlign='left'>
              {uniquelocationscount}    
            </Typography>
            <Typography fontWeight='300' fontSize='.8rem' color={uniquelocationscolor} textAlign='left' style={{ paddingTop: '5px', marginLeft: '5px'}}>
              {uniquelocations}                    
            </Typography>
          </Stack>
        </Grid2>
      </Grid2>
    );
  }

}

ProviderCommentsHeadlines.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  router: PropTypes.object.isRequired,
  app: PropTypes.object,
  user: PropTypes.object,
  headlinesReport: PropTypes.object.isRequired,
  outreachReport: PropTypes.object.isRequired,
  dateRange: PropTypes.object,
  encounterType: PropTypes.object,
  filterUser: PropTypes.object
};

function mapStateToProps(state) {
  const { app, analytics, user } = state;

  return {
    app,
    user,
    clientId: app.selectedClient,
    headlinesReport: StoreUtil.get(analytics, 'PROVIDERS_HEADLINES_COUNT'),
    outreachReport: StoreUtil.get(analytics, 'PROVIDERS_OUTREACH_COUNT'),

  };
}

const styled = withStyles(styles)(ProviderCommentsHeadlines);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ProviderCommentsHeadlines};
