import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
import { upsertProviderComment } from 'store/actions/providerCommentsActions';
import { ProviderCommentsDialog } from 'components/ProviderCommentsDialog';
import { withIsMobile } from 'utils';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Link,
  Stack,
  Tabs,
  Tab,
} from '@mui/material';
import { ActivityBell } from './ActivityBell';
import { ListItemActionButton } from './ListItemDetail';
import { useSelector, useDispatch } from 'react-redux';
import { StoreUtil } from 'doctivity-shared/utils';
import {
  loadRecentActivityNotes,
  loadUnresolvedActivityNotes,
} from 'store/actions/dashboardActions';
import TruncatedText from './TruncatedText';
import { showConfirmationDialog } from 'store/actions/systemActions';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    height: 560,
    [theme.breakpoints.down('xl')]: {
      height: 'unset',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '48px',
    borderBottom: `1px solid ${theme.palette.tertiary.heavyGrey}`,
    marginBottom: theme.spacing(2),
    height: '48px',
    maxHeight: '48px',
    minHeight: '48px',
  },
  tabs: {
    '& .MuiButtonBase-root': {
      paddingBottom: 0,
      paddingTop: 18,
      minHeight: '32px',
      color: theme.palette.tertiary.accent,
    },
    '& .MuiTabs-root': {
      minHeight: '38px',
    },
    '& .MuiTabs-indicator': {
      margin: 0,
      backgroundColor: theme.palette.tertiary.accent,
    },
  },
  headerTitle: {
    fontSize: '20px',
    display: 'flex',
    flexWrap: 'nowrap',
    textWrap: 'nowrap',
  },
  newNoteButton: {
    marginLeft: theme.spacing(1),
    marginTop: '2px',
    lineHeight: '16px',
    fontSize: '10px',
    textWrap: 'nowrap',
    height: '18px',
    alignSelf: 'center',
  },
  noteRowContainer: {
    '&:hover': {
      backgroundColor: theme.palette.tertiary.main,
    },
    '& a': {
      color: '#000 !important',
    },
    '& a:visited': {
      color: '#000 !important',
    },
  },
  noteRow: {
    background: 'white',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: '100%',
    '&:hover': {
      backgroundColor: theme.palette.tertiary.main,
    },
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spacing(1),
    textDecoration: 'none',
  },
  createDate: {
    color: theme.palette.tertiary.mediumGrey,
    fontWeight: 300,
    paddingLeft: theme.spacing(1),
  },
  dueDate: {
    color: theme.palette.tertiary.mediumGrey,
    fontWeight: 300,
  },
  body: {
    textWrap: 'nowrap',
    fontWeight: 300,
    fontSize: '12px',
    minWidth: '25%',
    overflow: 'hidden',
    maxWidth: '25%',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
  },
  notes: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  noteName: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  cardBody: {
    fontSize: '14px',
    display: 'flex',
    overflow: 'hidden',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    flexGrow: 1,
    alignItems: 'flex-start',
    overflow: 'hidden',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  allActivityLink: {
    cursor: 'pointer',
    color: '#214E56',
    fontSize: '12px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: '24px',
  },
  noFavorites: {
    color: '#707070',
    fontFamily: 'Roboto',
    '& > p > h5': {
      color: '#333',
      fontSize: 12,
    },
    fontSize: 12,
    [theme.breakpoints.up('lg')]: {
      paddingTop: '20px',
    },
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '25rem',
    justifyContent: 'flex-start',
  },
});

function ActivityNotes({ isMobile, isEarlyMobile, classes }) {
  const clientId = useSelector(({ app }) => app.selectedClient);
  const userId = useSelector(({ user }) => user.id);
  const user = useSelector(({ user }) => user);
  const [tab, setTab] = useState('unresolved');
  const [showNewNoteDialog, setShowNewNoteDialog] = useState(false);
  const [noteNames, setNoteNames] = useState([]);
  const [hasActivityNotes, setHasActivityNotes] = useState(null);
  const [hasNoUnresolvedNotes, setHasNoUnresolvedNotes] = useState(false);
  const [hasMovedTabBecauseNotLoaded, setHasMovedTabBecauseNotLoaded] =
    useState(false);
  const recentActivityNotes = useSelector(({ dashboard }) =>
    StoreUtil.get(dashboard, `analytics-recent-activitynotes-${clientId}`),
  );
  const unresolvedActivityNotes = useSelector(({ dashboard }) =>
    StoreUtil.get(dashboard, `analytics-unresolved-activitynotes-${clientId}`),
  );
  const activityNotes = (
    tab === 'unresolved' ? unresolvedActivityNotes : recentActivityNotes
  )?.data?.rows;
  const areUnresolvedNotesLoaded = StoreUtil.isLoaded(unresolvedActivityNotes);
  const editComment = useSelector(({ providerComments }) =>
    StoreUtil.get(providerComments, StoreUtil.COMMON_EDIT_ITEM),
  );
  const dispatch = useDispatch();
  const loadComments = useCallback(() => {
    if (tab === 'unresolved') {
      dispatch(loadUnresolvedActivityNotes(clientId, userId));
    } else if (tab === 'recent') {
      dispatch(loadRecentActivityNotes(clientId, userId));
    }
  }, [clientId, userId, dispatch, tab]);
  useEffect(() => {
    if (!clientId || !userId) return;
    if (
      tab === 'unresolved' &&
      !StoreUtil.isLoading(unresolvedActivityNotes) &&
      !StoreUtil.isLoaded(unresolvedActivityNotes)
    ) {
      loadComments();
    } else if (
      tab === 'recent' &&
      !StoreUtil.isLoading(recentActivityNotes) &&
      !StoreUtil.isLoaded(recentActivityNotes)
    ) {
      loadComments();
    }
  }, [tab, clientId, userId, unresolvedActivityNotes, recentActivityNotes]);
  useEffect(() => {
    if (StoreUtil.isSaved(editComment)) {
      loadComments(tab);
    }
  }, [tab, loadComments, editComment]);
  useEffect(() => {
    if (
      areUnresolvedNotesLoaded &&
      unresolvedActivityNotes?.data?.rows?.length === 0
    ) {
      setHasNoUnresolvedNotes(true);
      setTab('recent');
      loadComments();
    }
  }, [areUnresolvedNotesLoaded, unresolvedActivityNotes]);
  useEffect(() => {
    if (
      areUnresolvedNotesLoaded &&
      activityNotes?.length === 0 &&
      tab !== 'recent' &&
      !hasMovedTabBecauseNotLoaded
    ) {
      setHasMovedTabBecauseNotLoaded(true);
      setTab('recent');
    }
  }, [
    areUnresolvedNotesLoaded,
    activityNotes,
    tab,
    hasMovedTabBecauseNotLoaded,
  ]);
  useEffect(() => {
    setNoteNames(
      activityNotes?.map((note) => [
        ...note.providers?.map((p) => `${p.last_name}, ${p.first_name}`),
        ...note.contacts?.map((c) => `${c.last_name}, ${c.first_name}`),
        [
          note.location?.name,
          note.location?.address1,
          note.location?.city,
          note.location?.state,
        ]
          .filter(Boolean)
          .join(' '),
      ]),
    );
    if (tab === 'recent' && activityNotes?.length > 0) {
      setHasActivityNotes(true);
    }
  }, [activityNotes]);
  const isUnresolved = tab === 'unresolved';

  const openNewNoteDialog = () => {
    if (user.is_admin) {
      dispatch(
        showConfirmationDialog({
          title: 'Admin Users Can Not Create Notes',
          content: 'Login as a non-admin user to create activity notes.',
          confirmLabel: 'OK',
          onConfirm: () => {
            // do nothing
          },
        }),
      );
    } else {
      setShowNewNoteDialog(true);
    }
  };

  const onSaveNewNote = useCallback(
    (data) => {
      if (
        data.body &&
        data.body.length > 0
        // &&
        //(data.providers.length > 0 ||
          //data.contacts.length > 0 ||
          //data.location_id)
      ) {
        data.client_id = clientId;
        const nextAction = isUnresolved
          ? loadUnresolvedActivityNotes(clientId, userId)
          : loadRecentActivityNotes(clientId, userId);
        (data.user_id = userId),
          dispatch(upsertProviderComment(data, nextAction));
      }
      setShowNewNoteDialog(false);
    },
    [clientId, userId, dispatch],
  );

  return (
    <Card className={classes.container}>
      <CardContent className={classes.cardContent}>
        <div className={classes.header}>
          <div className={classes.headerTitle}>
            <span>Your Activity Notes</span>
            <ListItemActionButton
              className={classes.newNoteButton}
              //onClick={() => setShowNewNoteDialog(true)}
              onClick={() => openNewNoteDialog()} // setShowNewNoteDialog(true)}
            >
              + New Note
            </ListItemActionButton>
          </div>
          <Tabs variant='scrollable' className={classes.tabs} value={tab}>
            <Tab
              label='Unresolved'
              value='unresolved'
              onClick={() => setTab('unresolved')}
            />
            <Tab
              label='Most Recent'
              value='recent'
              onClick={() => setTab('recent')}
            />
          </Tabs>
        </div>
        <div className={classes.cardBody}>
          <div className={classes.notes}>
            {activityNotes &&
              activityNotes.length > 0 &&
              activityNotes.map((note, idx) => {
                return (
                  <Box
                    key={note.id}
                    sx={{
                      border: '1px solid #ebebeb',
                      height: 40,
                      borderRadius: '5px',
                      scrollMarginTop: '80px',
                      paddingLeft: '8px',
                      paddingRight: '8px',
                    }}
                    className={classes.noteRowContainer}
                  >
                    <Link
                      href={`/notes/${note.id}`}
                      className={classes.noteRow}
                    >
                      {isUnresolved && <ActivityBell dueDate={note.due_date} />}
                      <span
                        className={
                          isUnresolved ? classes.dueDate : classes.createDate
                        }
                      >
                        {moment(
                          isUnresolved
                            ? note.due_date
                            : note.comment_date || note.created_at,
                        ).format('M/DD')}
                      </span>
                      <TruncatedText
                        className={classes.noteName}
                        text={noteNames?.[idx] ?? []}
                      />
                      <span className={classes.body}>{note.body}</span>
                    </Link>
                  </Box>
                );
              })}
            {!isMobile &&
              !isEarlyMobile &&
              activityNotes?.length > 0 &&
              Array.from({ length: 10 - activityNotes.length }).map(
                (_, index) => {
                  return (
                    <Stack
                      key={`blank-${index}`}
                    >
                      <Box
                        sx={{
                          height: 42,
                          borderRadius: '5px',
                          borderTopRightRadius: '5px',
                          borderBottomRightRadius: '5px',
                          scrollMarginTop: '80px',
                        }}
                      />
                    </Stack>
                  );
                },
              )}

            {hasNoUnresolvedNotes && !hasActivityNotes && (
              <Grid
                item
                style={{
                  flexGrow: 1,
                  maxWidth: 'unset',
                }}
              >
                <div className={classes.noFavorites}>
                  <p>
                    Use Activity Notes to track interactions with providers,
                    locations, and contacts.
                  </p>
                  <p
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: '#214E56',
                    }}
                    onClick={() => openNewNoteDialog()} 
                  >
                    Make your first Activity Note
                  </p>
                </div>
              </Grid>
            )}
            {hasNoUnresolvedNotes && hasActivityNotes && tab !== 'recent' && (
              <Grid
                item
                style={{
                  flexGrow: 1,
                  maxWidth: 'unset',
                }}
              >
                <div className={classes.noFavorites}>
                  <p>
                    Add due dates, on Activity Notes to track follow-up with
                    providers, locations, and contacts.
                  </p>
                  <p
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: '#214E56',
                    }}
                    onClick={() => openNewNoteDialog()} 
                    
                  >
                    Make an Activity Note
                  </p>
                </div>
              </Grid>
            )}
          </div>
        </div>
        {showNewNoteDialog && (
          <ProviderCommentsDialog
            clientId={clientId}
            onSave={onSaveNewNote}
            open={showNewNoteDialog}
            onClose={() => setShowNewNoteDialog(false)}
          />
        )}
      </CardContent>
      {activityNotes && activityNotes.length > 0 && (
        <Link className={classes.allActivityLink} href={'/notes'}>
          See all activity notes
        </Link>
      )}
    </Card>
  );
}
ActivityNotes.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  isEarlyMobile: PropTypes.bool,
};

function mapStateToProps(state) {
  const { app, user } = state;

  return {
    app,
    user,
  };
}

const styled = withStyles(styles)(withIsMobile(ActivityNotes));
const connected = connect(mapStateToProps)(styled);
export { connected as ActivityNotes };
