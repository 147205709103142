import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import fileDownload from 'js-file-download';
import {
  Typography,
} from '@mui/material';
import {
  ConfirmationDialog,
  SmallIconTextButton,
} from 'components';
import axiosInstance from 'utils/axiosUtil';

const styles = (theme) => ({
  textInput: {
    width: '100%',
  },
  headerItem: {
    fontSize: 14,
    paddingTop: 25,
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
});

class ManageClientDataDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmCloseOpen: false,
      confirmTitle: '',
      confirmDescription: '',
      confirmDataType: null,
      confirmDeleting: false,
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          maxWidth='sm'
          fullWidth
        >
          <DialogTitle id='alert-dialog-title'>
            Manage Data for {this.props.clientName}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <Typography className={classes.headerItem}>
                Affiliate Organizations
              </Typography>
              <SmallIconTextButton onClick={this.onDownloadOrganizationClick} icon='download' text='Download'/>
              <SmallIconTextButton disabled icon='delete' text='Delete'/>

              <Typography className={classes.headerItem}>
                Affiliate Providers
              </Typography>
              <SmallIconTextButton onClick={this.onDownloadProvidersClick} icon='download' text='Download'/>
              <SmallIconTextButton onClick={this.onDeleteProviders} icon='delete' text='Delete'/>

              <Typography className={classes.headerItem}>
                Visits &amp; Referrals
              </Typography>
              <SmallIconTextButton disabled icon='download' text='Download'/>
              <SmallIconTextButton onClick={this.onDeleteVisits} icon='delete' text='Delete'/>

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.onCancel || this.props.onClose}
              color='primary'
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <ConfirmationDialog
          open={this.state.confirmCloseOpen}
          title={this.state.confirmTitle}
          content={this.state.confirmDeleting ? 'Permanently deleting all data...' : this.state.confirmDescription}
          confirmLabel={this.state.confirmDeleting ? 'Deleting...' : 'Permanently Delete'}
          onClose={this.onCloseConfirmCancel}
          onCancel={this.onCloseConfirmCancel}
          onConfirm={this.state.confirmDeleting ? undefined : this.onConfirmDelete}
        />
      </>
    );
  }

  onDownloadOrganizationClick = () => {
    axiosInstance.get('/AffiliatedOrganizationsDownload/' + this.props.clientId).then((result) => {
      fileDownload(result.data, 'affiliated_orgs_export.csv');
    });
  }

  onDownloadProvidersClick = () => {
    axiosInstance.get('/AffiliatedProvidersDownload/' + this.props.clientId).then((result) => {
      fileDownload(result.data, 'affiliated_providers_export.csv');
    });
  }

  onDeleteProviders = () => {
    this.setState({
      confirmCloseOpen: true,
      confirmTitle: `Delete ${this.props.clientName} Affiliate Providers`,
      confirmDescription: 'Clicking DELETE will delete ALL affiliate data for providers for this client. This CANNOT be undone.',
      confirmDataType: 'provider_affiliate',
      confirmDeleting: false,
    });
  }

  onDeleteVisits = () => {
    this.setState({
      confirmCloseOpen: true,
      confirmTitle: `Delete ${this.props.clientName} Visits Data`,
      confirmDescription: 'Clicking DELETE will delete ALL uploaded visits and referral data for this client. This CANNOT be undone.',
      confirmDataType: 'visits',
      confirmDeleting: false,
    });
  }

  onCloseConfirmCancel = () => {
    this.setState({
      confirmCloseOpen: false
    });
  };

  onConfirmDelete = () => {
    this.setState({
      confirmDeleting: true,
    });

    axiosInstance.delete('/ClientsData', {
      data: {
        clientId: this.props.clientId,
        dataType: this.state.confirmDataType,
      }
    }).then(() => {
      this.setState({
        confirmCloseOpen: false,
        confirmDeleting: false,
      });
    });
  }
}

ManageClientDataDialog.propTypes = {
  open: PropTypes.bool,
  classes: PropTypes.object,
  title: PropTypes.string,
  clientId: PropTypes.number,
  clientName: PropTypes.string,
  content: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  confirmLabel: PropTypes.string,
  onCancel: PropTypes.func,
  cancelLabel: PropTypes.string,
};

function mapStateToProps() {
  return {
  };
}

const styled = withStyles(styles)(ManageClientDataDialog);
const connected = connect(mapStateToProps)(styled);
export { connected as ManageClientDataDialog };
