import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
} from '@mui/material';

const styles = () => ({
  buttonText: {
    paddingTop: 3,
    paddingLeft: 2,
  },
});

class SmallIconTextButton extends Component {
  render() {
    const {
      classes,
      icon,
      text,
      onClick,
    } = this.props;

    return (
      <Button key='button' color='secondary' size='small' onClick={onClick} disabled={this.props.disabled}>
        <Icon fontSize='small'>{icon}</Icon>
        <span className={classes.buttonText}>{text}</span>
      </Button>
    );
  }
}

SmallIconTextButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

const styled = withStyles(styles)(SmallIconTextButton);
export { styled as SmallIconTextButton };
