import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Tabs,
  Tab,
  Chip,
} from '@mui/material';
import PropTypes from 'prop-types';

import { StoreUtil } from 'doctivity-shared/utils';
import { LocationsUtil, ActivityLogsUtil } from 'utils';

import { DynamicTable } from 'components';
import { queryLocations } from 'store/actions/locationsActions';
import { activityLogsByUserId } from 'store/actions/activityLogsActions';
import { loadAnalytics } from 'store/actions/analyticsActions';

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: 'Name',
    key: 'name',
    fulltext: true,
  },
  {
    label: 'Organization',
    key: 'organization.name',
    fulltext: true,
  },
  {
    label: 'Address',
    key: 'address1',
    format: (address1, location) =>
      location.address2 ? address1 + ', ' + location.address2 : address1,
    filterFormatter: LocationsUtil.formatAddress1,
  },
  {
    label: 'City',
    key: 'city',
    fulltext: true,
  },
  {
    label: 'State',
    key: 'state',
    style: { width: 50 },
    filterExact: true,
    autoCapitalize: true,
  },
  /*{
    label: 'Zip',
    key: 'postal_code',
    style: { width: 80 },
  },
  */
];

const styles = (theme) => ({
  filters: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  instructions: {
    fontSize: 14,
    fontWeight: 300,
    flex: 1,
    color: theme.palette.primary.main,
  },
  table: {
    minHeight: 420,
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
});

class FindLocationDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locationCategory:
        props.selectedProviderId || props.selectedContactId
          ? 'suggested'
          : 'recent',
      query: {
        where: {},
        limit: 10,
        offset: 0,
        include: [
          {
            association: 'organization',
            attributes: ['id', 'name'],
            required: false,
          },
        ],
        order: [['id', 'ASC']],
      },
      selectedSmartLocations: props.location ? [props.location] : [],
    };
  }

  componentDidMount() {
    const { locations, organization } = this.props;

    if (StoreUtil.needsLoadNoCache(locations) || organization) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    // is object getting edited?
    if (
      this.props.location &&
      (!prevProps.location || this.props.location.id !== prevProps.location.id)
    ) {
      this.setInitialLocation();
    }
  }

  setInitialLocation() {
    const {
      location: { city, state },
    } = this.props;
    const where = {};

    if (city && city !== '') {
      where.city = { $match: city };
    }
    if (state && state !== '') {
      where.state = { $eq: state };
    }
    this.setState(
      (state) => ({
        query: {
          ...state.query,
          where,
        },
      }),
      this.fetchData
    );
  }

  onQueryChange = (query) => {
    console.log(query);

    // if searching by organization name, require org
    if (query.include[0].where && Object.keys(query.include[0].where).length) {
      query.include[0].required = true;
    } else {
      query.include[0].required = false;
    }

    this.setState({ query }, this.fetchData.bind(this));
  };

  fetchData() {
    const {
      dispatch,
      selectedProviderId,
      selectedLocationId,
      selectedContactId,
      userId,
      clientId,
    } = this.props;
    const { query, locationCategory, selectedSmartLocations, } = this.state;

    switch (locationCategory) {
      case 'suggested':

      let locationsArr = selectedLocationId;
        
        if (selectedSmartLocations.length > 0 && locationsArr) {
          locationsArr = locationsArr.concat(selectedSmartLocations.map((l) => l.id));
        }
        dispatch(
          loadAnalytics({
            type: 'LOCATIONS_SMART_SUGGESTIONS',
            filter: {
              client_id: clientId,
              user_id: userId,
              selectedProviderId,
              selectedContactId,
            },
            opts: query,
          })
        );


        if (!locationsArr || locationsArr.length === 0) {
          dispatch(activityLogsByUserId(userId, 'Locations', clientId, query));
        }

        break;
      case 'recent':
        dispatch(activityLogsByUserId(userId, 'Locations', clientId, query));
        break;
      default:
        dispatch(queryLocations(query));
        break;
    }
  }

  render() {
    const {
      classes,
      locations,
      open,
      organization,
      smartLocations,
      recents,
      onMultiSelect,
      showSuggested,
    } = this.props;
    const { query, locationCategory, selectedSmartLocations } = this.state;

    let modifiedColumns = [...columns];
    if (organization) {
      modifiedColumns = modifiedColumns.filter(
        (column) => column.label !== 'Organization'
      );
    }

    return (
      <Dialog
        onClose={this.onCancel}
        aria-labelledby='simple-dialog-title'
        open={open}
        fullWidth
        maxWidth='lg'
        scroll='paper'
      >
        <DialogTitle>Select a Location</DialogTitle>
        <DialogContent dividers>
          <Tabs
            variant='scrollable'
            className={classes.tabs}
            value={locationCategory}
            onChange={this.onTabChange}
          >
            {showSuggested && (
              <Tab label='Suggested' value='suggested' disableRipple />
            )}
            <Tab label='Recent' value='recent' disableRipple />
            <Tab label='All' value='all' disableRipple />
          </Tabs>

          {onMultiSelect && (
            <div className={classes.selectedLocations}>
              {selectedSmartLocations.map((l) => (
                <Chip
                  label={`${l.name ? l.name + ', ' : ''} ${l.address1}`}
                  onDelete={
                    location && l.id === location.id
                      ? undefined
                      : () => {
                          this.onDeleteLocation(l);
                        }
                  }
                  variant='outlined'
                  key={l.id}
                />
              ))}
            </div>
          )}


          <div className={classes.filters}>
            <Typography className={classes.instructions}>
              Filter and select the location
              {!organization && ' and organization'}
            </Typography>
          </div>
          <div className={classes.table}>
            {locationCategory === 'suggested' && (
              <>
                <DynamicTable
                  columns={modifiedColumns}
                  query={query}
                  data={smartLocations?.data?.rows?.length>0 ? smartLocations : recents}
                  multiSelectedRows={selectedSmartLocations}s
                  noDataMessage='No locations found.'
                  onQueryChange={this.onQueryChange}
                  onRowClick={!onMultiSelect ? this.onRowClick : undefined}
                  onMultiSelect={
                    onMultiSelect ? this.onSmartSuggestionSelected : undefined
                  }
                  ContainerType={React.Fragment}
                />
              </>
            )}
            {locationCategory === 'recent' && (
              <DynamicTable
                columns={modifiedColumns}
                query={query}
                data={recents}
                multiSelectedRows={selectedSmartLocations}
                noDataMessage='No locations found.'
                onQueryChange={this.onQueryChange}
                onRowClick={!onMultiSelect ? this.onRowClick : undefined}
                onMultiSelect={
                  onMultiSelect ? this.onSmartSuggestionSelected : undefined
                }

                ContainerType={React.Fragment}
                className={classes.table}
              />
            )}
            {locationCategory === 'all' && (
              <DynamicTable
                columns={modifiedColumns}
                query={query}
                data={locations}
                multiSelectedRows={selectedSmartLocations}
                noDataMessage='No locations found.'
                onQueryChange={this.onQueryChange}
                onRowClick={!onMultiSelect ? this.onRowClick : undefined}
                onMultiSelect={
                  onMultiSelect ? this.onSmartSuggestionSelected : undefined
                }
                ContainerType={React.Fragment}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onCancel} color='primary'>
            Cancel
          </Button>
          {onMultiSelect && (
            <Button
              onClick={this.onSaveMultiSelect}
              variant='contained'
              color='primary'
            >
              Save
            </Button>
          )}
          </DialogActions>
      </Dialog>
    );
  }

  onCancel = () => {
    const { onSelect, onMultiSelect } = this.props;

    if (onSelect) {
      if (this.props.onClose) {
        this.props.onClose();
      }
    } else if (onMultiSelect) {
      onMultiSelect(null);
    }
  };

  onRowClick = (row) => {
    const { onSelect } = this.props;

    if (onSelect) {
      onSelect(row);
    }
  };

  onSaveMultiSelect = () => {
    const { onMultiSelect } = this.props;
    const { selectedSmartLocations } = this.state;

    if (onMultiSelect) {
      onMultiSelect(selectedSmartLocations);
    }
  };

  onSmartSuggestionSelected = (selectedLocations) => {
    const shouldFetch = true; //selectedProviders.length <= 1;
    this.setState(
      { selectedSmartLocations: selectedLocations },
      () => shouldFetch && this.fetchData(),
    );
  };

  onTabChange = (_event, newValue) => {
    const { query } = this.state;

    this.setState(
      {
        locationCategory: newValue,
        query: { ...query, where: {} },
      },
      this.fetchData.bind(this)
    );
  };

  onDeleteLocation = (location) => {
    const { selectedSmartLocations } = this.state;

    const newSelectedLocations = selectedSmartLocations.filter(
      (selected) => selected.id !== location.id,
    );

    this.setState(
      {
        selectedSmartLocations: newSelectedLocations,
      },
      //() => newSelectedProviders.length === 0 && this.fetchData(),
      () => this.fetchData(),
    );
  };
}

FindLocationDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onMultiSelect: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  smartLocations: PropTypes.object.isRequired,
  recents: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  locations: PropTypes.object,
  organization: PropTypes.object,
  location: PropTypes.object,
  selectedProviderId: PropTypes.number,
  selectedContactId: PropTypes.number,
  selectedLocationId: PropTypes.number,
  showSuggested: PropTypes.bool,
};

function mapStateToProps(state) {
  const { app, analytics, locations, activityLogs } = state;

  const userId = state.user ? state.user.id : null;

  return {
    userId,
    clientId: app.selectedClient,
    locations: StoreUtil.get(locations, StoreUtil.COMMON_TABLE),
    recents: ActivityLogsUtil.getLocationsFromLogs(activityLogs, userId),
    smartLocations: StoreUtil.get(analytics, 'LOCATIONS_SMART_SUGGESTIONS'),
  };
}

const styled = withStyles(styles)(FindLocationDialog);
const connected = connect(mapStateToProps)(styled);
export { connected as FindLocationDialog };
