import React,
{
  Component,
} from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
  Divider,
  Typography,
  Hidden,
  Menu,
  MenuItem,
} from '@mui/material';

import { LoadingView } from 'components';
import { StoreUtil } from 'doctivity-shared/utils';
import { withRouter } from 'utils';

import { selectClient } from 'store/actions/appActions';
import {
  showConfirmationDialog,
} from 'store/actions/systemActions';
import { listClients } from 'store/actions/clientsActions';
import { logoutUser } from 'store/actions/loginActions';
import { Header } from './Header';
import logo from 'assets/images/logo_small.png';
import UpgradeableContext from './UpgradeableContext';

const drawerWidth = 224;
const minimizedDrawerWidth = 100;

const styles = (theme) => ({
  logo: {
    padding: 8,
    width: 84,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logoOpen: {
    width: 192,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  logoContainer: {
    minHeight: 111,
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: '#fafafa',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      left: minimizedDrawerWidth,
      right: 0,
    },
  },
  container: {
    padding: 0,
    minHeight: '80%', // enough to get footer down
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    border: 'none',
    overflowX: 'hidden',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: minimizedDrawerWidth,
  },
  drawerPaper: {
    backgroundColor: '#00A887',
    scrollbarWidth: 'thin',
    scrollbarColor: '#7F7F7F transparent',
  },
  drawerPaperOpen: {
    boxShadow: '6px 0px 6px #00000029',
  },
  minimizedDrawer: {
    width: minimizedDrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  mobileDrawer: {},
  mobileDrawerPaper: {
    backgroundColor: '#00A887',
  },
  listItem: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 35,
    paddingRight: 0,
    color: '#fff',
    '&:hover': {
      '& *': {
        color: '#fff',
      },
      color: '#fff',
    },
  },
  listItemIcon: {
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: 30,
    color: 'rgba(255, 255, 255, 0.5)',
  },
  listItemSelected: {
    color: '#fff',
    backgroundColor: theme.palette.primary.accent + '4E',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.accent + '4E',
    },
  },
  listItemIconSelected: {
    color: '#fff',
  },
  logoutSubheader: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  logoutSubheaderContainer: {
    lineHeight: '20px',
    backgroundColor: '#00A887',
  },
  cardContainer: {
    marginTop: 200,
    marginLeft: 200,
    width: 350,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  button: {
    width: 150,
  },
  cardContent: {
    marginTop: 0,
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  link: {
    color: '#00A887',
  },
  listSubheader: {
    backgroundColor: '#00A887',
    color: '#fff',
  },
  selectedWedgeIcon: {
    color: '#FAFAFA',
    marginRight: -17,
    fontSize: 40,
    position: 'absolute',
    right: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  divider: {
    borderColor: '#05BE8D',
  },
  accountIcon: {
    height: 16,
    width: 16,
    marginLeft: -20,
    paddingRight: 4,
    color: theme.palette.primary.bright,
  },
  logout: {
    '&:hover': {
      textDecoration: 'underline',
    },
    color: '#FFFFFF',
  },
  clientContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: minimizedDrawerWidth - 5 * parseInt(theme.spacing()),
    height: 24,
    backgroundColor: theme.palette.secondary.accent,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    padding: theme.spacing(),
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: theme.spacing(2),
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shorter,
    }),
  },
  clientIcon: {
    fontSize: 12,
    fontWeight: 300,
    marginLeft: theme.spacing(),
    color: theme.palette.primary.accent,
  },
  clientName: {
    fontSize: 12,
    color: theme.palette.primary.accent,
  },
  clientContainerOpen: {
    maxWidth: drawerWidth - 5 * parseInt(theme.spacing()),
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
  },
  clientListItem: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: 0,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    color: theme.palette.primary.accent,
  },
  listItemText: {
    marginLeft: -16,
  },
  userNameContainer: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    width: minimizedDrawerWidth - 20,
  },
  userNameContainerLong: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: minimizedDrawerWidth + 40,
  },
  userName: {
    fontSize: 11,
    textAlign: 'center',
  },
});

class AppNavigation extends Component {
  static context = UpgradeableContext;  
  constructor(props) {
    super(props);

    this.state = {
      drawerOpen: false,
      clientMenuOpen: false,
      waitingForMouseExit: false,
    };
  }

  componentDidMount() {
    const { user, clients, dispatch } = this.props;

    if (user && user.id && StoreUtil.needsLoadNoCache(clients)) {
      dispatch(listClients());
    }
  }

  componentDidUpdate(props) {
    if (
      this.props.user &&
      (!props.user || this.props.user.id !== props.user.id)
    ) {
      dispatch(listClients());
    }
  }

  onDrawerOpen = () => {
    this.setState({ drawerOpen: true });
  };

  onDrawerClose = () => {
    this.setState({ drawerOpen: false });
  };

  onMouseEnter = () => {
    if (!this.state.waitingForMouseExit) {
      this.onDrawerOpen();
    }
  };

  onMouseLeave = () => {
    this.setState({ waitingForMouseExit: false });
    this.onDrawerClose();
  };

  onSelectNavItem = () => {
    this.setState({ waitingForMouseExit: true });
    this.onDrawerClose();
  };

  onLogout = () => {
    const { dispatch, router } = this.props;
    router.navigate('/');
    // Assume all session storage usage is per user
    sessionStorage.clear();
    dispatch(logoutUser());
  }

  onContactSupport = () => {
    const { classes, dispatch } = this.props;

    dispatch(
      showConfirmationDialog({
        title: 'Doctivity Support',
        content: (
          <>
            <p>
              Support and documentation for Doctivity is available at our
              self-service knowledge base.
            </p>
            <p>
              <a
                href='https://doctivity-health.helpscoutdocs.com/'
                target='_blank'
                rel='noreferrer noopener'
                className={classes.link}
              >
                https://doctivity-health.helpscoutdocs.com/
              </a>{' '}
              (Opens in New Tab)
            </p>

            <p>You may also email us at support@doctivityhealth.com</p>
          </>
        ),
        confirmLabel: 'Visit Knowledge Base',
        onConfirm: () => {
          window.open('https://doctivity-health.helpscoutdocs.com/', '_blank');
        },
        onCancel: () => {
          // do nothing
        },
      })
    );
  };

  setClient = (client) => {
    const { dispatch, selectedClient } = this.props;

    if (selectedClient !== client.id) {
      dispatch(selectClient(client.id));
    }
  };

  render() {
    const { classes, user, router, clients, app } = this.props;
    const { drawerOpen: _drawerOpen } = this.state;
    const drawerOpen = this.drawerOpenOrOnDashboard();

    if (!StoreUtil.isLoaded(clients)) {
      // don't load anything until clients are loaded
      return <LoadingView />;
    }

    let client = null;
    if (app.selectedClient && StoreUtil.isLoaded(clients)) {
      const data = StoreUtil.getData(clients);
      client = data.find((c) => c.id === parseInt(app.selectedClient));
    }
    const dashboard = router.location.pathname === '/' ||
      router.location.pathname.startsWith('/dashboard');

    const drawerItems = (
      <>
        <Divider className={classes.divider} />
        <List disablePadding>{this.getClientItems()}</List>
        <Divider className={classes.divider} />
        <List disablePadding>{this.getExploreItems(client)}</List>
        {user.is_admin && (
          <>
            <Divider className={classes.divider} />
            <List disablePadding>{this.getAdminItems()}</List>
          </>
        )}
        <Divider className={classes.divider} />
        <List disablePadding>{this.getAccountItems()}</List>
      </>
    );

    return (<>
        <Header
          onDrawerOpen={_drawerOpen ? this.onDrawerClose : this.onDrawerOpen}
        />

        <Hidden mdUp>
          <Drawer
            variant='temporary'
            anchor='left'
            open={_drawerOpen}
            onClose={this.onDrawerClose}
            className={clsx(classes.drawer, classes.mobileDrawer)}
            classes={{
              paper: classes.mobileDrawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawerItems}
          </Drawer>
          </Hidden>

          <Hidden mdDown>
          <Drawer
            variant='permanent'
            classes={{
              paper: `${classes.drawer} ${classes.drawerPaper} ${
                drawerOpen ? !dashboard && classes.drawerPaperOpen : classes.drawerPaperClose
              }`,
            }}
            open={drawerOpen}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
          >
            <>
              <div
                className={classes.logoContainer}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Link to='/dashboard'>
                  <img
                    src={logo}
                    className={clsx(classes.logo, {
                      [classes.logoOpen]: drawerOpen,
                    })}
                    alt='Logo'
                  />
                </Link>
              </div>
              {drawerItems}
            </>
          </Drawer>
        </Hidden>
      </>
    );
  }

  getItem = (
    path,
    icon,
    name,
    disabled = false,
    handler = null,
    alternativePaths = [],
    iconStyles = {}
  ) => {
    const { router, classes } = this.props;

    const drawerOpen = this.drawerOpenOrOnDashboard();

    const isDashboard =
      router.location.pathname === '/' ||
      router.location.pathname.startsWith('/dashboard');
    const itemSelected =
      (path === '/dashboard' && router.location.pathname === '/') ||
      router.location.pathname.startsWith(path) ||
      alternativePaths.some((path) =>
        router.location.pathname.startsWith(path)
      );
    const MenuIcon = (typeof icon === 'string' ? () => (<Icon
              className={`${this.props.classes.listItemIcon} ${
                itemSelected && this.props.classes.listItemIconSelected
              }`}
            >
              {icon}
            </Icon>) : icon);

    return (<UpgradeableContext.Consumer>
      {(ctx) => 
      <ListItem
        button
        key={path}
        component={handler ? undefined : Link}
        to={handler ? undefined : path}
        disabled={disabled}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          ctx.setHasNavigated(true);    
          if (handler) {
            handler();
          } else {
            this.props.router.navigate(path);
          }
          this.onSelectNavItem();
        }}
        className={`${this.props.classes.listItem} ${
          itemSelected && this.props.classes.listItemSelected
        }`}
      >
        <>
          <ListItemIcon
            key='icon'
            style={{ minWidth: drawerOpen ? '56px' : 0, ...iconStyles }}
          >
            <MenuIcon />
         </ListItemIcon>
          {drawerOpen && (
            <ListItemText
              key='text'
              primary={name}
              className={classes.listItemText}
              primaryTypographyProps={
                itemSelected ? { fontWeight: 700 } : { fontWeight: 300 }
              }
            />
          )}
          {itemSelected && (isDashboard || !drawerOpen) && (
            <Icon className={this.props.classes.selectedWedgeIcon} style={{ ...(isDashboard ? { color: '#214E56'} : {})}}>
              arrow_left
            </Icon>
          )}
        </>
      </ListItem>}
      </UpgradeableContext.Consumer>
    );
  };
  
  getExploreItems = (client) => {
    const drawerOpen = this.drawerOpenOrOnDashboard();
    const items = [
      this.getItem('/dashboard', 'dashboard_icon', 'Dashboard', false, null),
      this.getItem('/notes', 'speaker_notes', 'Activity Notes'),
      this.getItem('/labels', 'sell', 'Labels', false, null, [
        '/label_types',
      ]),
      this.getItem('/providers', 'people', 'Providers', false, null, [
        '/providers',
        '/recent_providers',
        '/referring_providers',
      ]),
      this.getItem('/contacts', 'contact_phone', 'Contacts'),
      this.getItem('/locations', 'place', 'Locations', false, null, [
        '/locations',
        '/recent_locations',
        '/favorite_locations',
      ]),
      this.getItem(
        '/organizations',
        'apartment',
        'Organizations',
        false,
        null,
        ['/organizations', '/affiliated_organizations', '/recent_organizations']
      ),
    ];
    if (client && client.has_claims_access) {
      items.splice(
        2,
        0,
        this.getItem('/claims', 'leaderboard', 'Market Data', false, null, [], {
          transform: 'rotate(90deg)',
          transformOrigin: drawerOpen ? '27% 50%' : '50% 50%',
        })
      );
    }

    items.push(
      this.getItem('/visits/', 'swap_horiz', 'Visits & Referrals'),
      this.getItem('/documents', 'description', 'Documents')
    );
    return items;
  };

  getAdminItems() {
    return [
      this.getItem('/users', 'face', 'Users'),
      this.getItem('/clients', 'account_box', 'Clients'),
      this.getItem('/taxonomy', 'folder_special', 'Specialties'),
      this.getItem('/procedures', 'local_hospital', 'Procedures'),
      this.getItem('/rvu_benchmarks', 'speed', 'RVU Benchmarks'),
      this.getItem('/upload', 'cloud_upload', 'Upload'),
      this.getItem('/activity', 'history', 'Activity Log'),
      this.getItem('/login_settings', 'network_locked', 'Login Settings'),
    ];
  }

  getClientItems() {
    const { clientMenuOpen } = this.state;
    const { classes, clients, selectedClient } = this.props;

    const drawerOpen = this.drawerOpenOrOnDashboard();
    let client;
    let clientName = '';
    const clientData = StoreUtil.getData(clients);
    if (selectedClient) {
      client = clientData?.find((c) => c.id === parseInt(selectedClient));
    }

    if (client) {
      clientName = client.name;
    }

    if (!drawerOpen) {
      clientName = this.getAbbrieveateName(clientName);
    }

    const showDrawer = clientData.length > 1;

    return [
      <ListItem
        button
        key='client-select'
        onClick={() => {
          if (showDrawer) {
            this.setState({ clientMenuOpen: !clientMenuOpen });
          }
        }}
        className={classes.clientListItem}
        style={{
          cursor: !showDrawer && 'default',
          background: 'transparent',
        }}
        ref={(b) => {
          this.clientButtonRef = b;
        }}
        disableRipple={!showDrawer}
      >
        <>
          <div
            className={`${classes.clientContainer} ${
              drawerOpen && classes.clientContainerOpen
            }`}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Typography noWrap className={classes.clientName}>
                {clientName}
              </Typography>
              {clientData.length > 1 && (
                <Icon className={classes.clientIcon}>expand_more</Icon>
              )}
            </div>
          </div>
          <Menu
            open={clientMenuOpen}
            anchorEl={this.clientButtonRef}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            onClose={() => {
              this.closeClientMenu();
              this.onDrawerClose();
            }}
          >
            {clientData &&
              clientData.map((c) => (
                <MenuItem
                  value={c.id}
                  key={c.id}
                  onClick={() => {
                    this.setClient(c);
                    this.onDrawerClose();
                  }}
                >
                  {c.name}
                </MenuItem>
              ))}
          </Menu>
        </>
      </ListItem>,
    ].concat([this.getUserItem()]);
  }

  getUserItem = () => {
    const { classes, router, user } = this.props;

    const drawerOpen = this.drawerOpenOrOnDashboard();
    const path = `/users/${user.id}`;
    const itemSelected = router.location.pathname.startsWith(path);

    return (
      <ListItem
        button
        key={path}
        component={Link}
        to={path}
        onClick={() => {
          router.navigate(path);
          this.onSelectNavItem();
        }}
        className={`${classes.listItem} ${
          itemSelected && classes.listItemSelected
        }`}
        style={{
          paddingLeft: !drawerOpen ? 0 : undefined,
          width: !drawerOpen ? '100%' : undefined,
        }}
      >
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: !drawerOpen ? '100%' : undefined,
              height: 56,
            }}
          >
            <ListItemIcon
              key='icon'
              style={{ minWidth: drawerOpen ? '56px' : 0 }}
            >
              <Icon
                className={`${this.props.classes.listItemIcon} ${
                  itemSelected && this.props.classes.listItemIconSelected
                }`}
              >
                account_circle_outline
              </Icon>
            </ListItemIcon>
            {!drawerOpen && (
              <div className={classes.userNameContainer}>
                <Typography
                  variant='caption'
                  noWrap
                  className={classes.userName}
                >
                  {user.first_name} {user.last_name}
                </Typography>
              </div>
            )}
          </div>
          {drawerOpen && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                wordWrap: 'break-word',
                alignSelf: 'start',
              }}
              className={classes.listItemText}
            >
              <div className={classes.userNameContainerLong}>
                <Typography
                  variant='caption'
                  noWrap
                  className={classes.userName}
                >
                  {user.first_name} {user.last_name}
                </Typography>
              </div>
              <Typography
                variant='button'
                fontSize={10}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  this.onLogout();
                }}
                className={classes.logout}
              >
                Logout
              </Typography>
            </div>
          )}
          {itemSelected && !drawerOpen && (
            <Icon
              className={classes.selectedWedgeIcon}
              style={{ position: 'absolute', right: 0 }}
            >
              arrow_left
            </Icon>
          )}
        </>
      </ListItem>
    );
  };

  getAccountItems = () => {
    return [
      this.getItem(
        '/support',
        'support_agent',
        'Support',
        false,
        this.onContactSupport
      ),
    ];
  };

  getAbbrieveateName(clientName) {
    const words = clientName.split(' ');
    let abbr = '';
    if (words.length > 1) {
      words.forEach((word) => {
        abbr += word.substring(0, 1);
      });
    } else {
      abbr = clientName.substring(0, 3);
    }
    return abbr;
  }

  closeClientMenu = () => {
    this.setState({
      clientMenuOpen: false,
      // waitingForMouseExit: true,
    });
  };

  drawerOpenOrOnDashboard = () => {
    const { router } = this.props;
    const { drawerOpen } = this.state;

    return (
      drawerOpen ||
      router.location.pathname === '/' ||
      router.location.pathname.startsWith('/dashboard')
    );
  };
}

AppNavigation.propTypes = {
  router: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  selectedClient: PropTypes.number,
  user: PropTypes.object,
  clients: PropTypes.object,
  app: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    app: state.app,
    user: state.user,
    selectedClient: state.app.selectedClient,
    clients: StoreUtil.get(state.clients, StoreUtil.COMMON_LIST),
  };
}
const styledApp = withStyles(styles)(AppNavigation);
const connected = connect(mapStateToProps)(styledApp);
const routed = withRouter(connected);
export { routed as AppNavigation };
