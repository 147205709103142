import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  Icon,
  Tabs,
  Tab,
  Typography,
  Card,
  CardContent,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import axiosInstance from 'utils/axiosUtil';
import { StoreUtil, ClaimQueryType, PatientType } from 'doctivity-shared/utils';
import { ContainedSmallIconButton } from 'components';
import {
  DynamicTable,
  FixedHeader,
  StickyHeader,
  AffiliatedIcon,
  FindProviderDialog,
  ProviderCommentsByTag,
  ProviderProjectedPatientsChart,
  OrgProjectedPatientsChart,
  SharedAffiliationChart,
  SmallIconTextButton,
  LoadingView,
  TitleTooltip,
} from 'components';
import { listClaimsServicelines } from 'store/actions/claimsActions';
import { getTag } from 'store/actions/tagsActions';
import { loadAnalytics } from 'store/actions/analyticsActions';
import {
  withAppbarHeight,
  withRouter,
  ServiceLineUtil,
  withIsMobile,
} from 'utils';
import {
  upsertProviderTag,
  deleteProviderTag,
  queryProviders,
} from 'store/actions/providerTagsActions';
import { TagSelector } from './TagSelector';
import { LabelProviderFlow } from './LabelProviderFlow';
import { OrganizationsByTag } from './OrganizationsByTag';

const styles = (theme) => ({
  tagIcon: {
    color: theme.palette.secondary.accent,
    marginRight: theme.spacing(1),
    alignSelf: 'center',
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
  },
  tabs: {
    backgroundColor: theme.palette.tertiary.main,
    paddingLeft: theme.spacing(3),
    color: '#777',
    '& .MuiButtonBase-root': {
      color: '#777',
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    '& .Mui-selected ': {
      color: `${theme.palette.secondary.main} !important`,
    },
  },
  spacer: {
    flex: 1,
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingBottom: theme.spacing(2),
  },
  header: {
    backgroundColor: theme.palette.primary.accent,
    flex: 1,
    padding: theme.spacing(3),
    paddingBottom: 29,
    display: 'flex',
    flexDirection: 'row',
    color: `${theme.palette.tertiary.main} !important`,
    flexWrap: 'wrap',
    gap: theme.spacing(5),
  },
  headerRight: {
    alignSelf: 'center',
  },
  tagHeader: {
    flex: 1,
    padding: theme.spacing(3),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    color: theme.palette.primary.main,
  },
  tagName: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.tertiary.main,
    paddingLeft: 58,
    paddingBottom: 3,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      alignItems: 'start',
    },
  },
  headerBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'normal',
  },
  headerTitle: {
    color: '#000',
    paddingRight: 30,
    fontSize: 24,
    fontWeight: 500,
    fontSize: 'clamp(16px, 2.5vw, 24px)',
    paddingRight: 30,
    marginTop: -3,
  },
  headerIcon: {
    color: theme.palette.secondary.accent,
    marginRight: theme.spacing(1),
    paddingTop: 23,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
    },
  },
  headerItem: {
    fontSize: 14,
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  headerItemNoPadding: {
    fontSize: 14,
    paddingRight: 15,
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  headerItemSpacing: {
    marginLeft: theme.spacing(0),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(4.25),
    },
  },
  headerEmptySpacer: {
    paddingBottom: 21,
  },
  stickyContainer: {
    backgroundColor: theme.palette.tertiary.main,
  },
  container: {
    padding: theme.spacing(3),
    overflow: 'auto',
  },
  condensedCell: {
    padding: 2,
    fontSize: 12,
    fontWeight: 'inherit',
  },
  emptyExpandedRowData: {
    fontSize: 12,
    fontWeight: 'inherit',
  },
  tagType: {
    color: theme.palette.primary.main,
  },
  affiliatedIcon: {
    fontSize: 14,
    verticalAlign: 'text-top',
    color: theme.palette.secondary.main,
  },
  regRow: {
    '& .manageIcons': {
      visibility: 'hidden',
      display: 'flex',
      justifyContent: 'flex-start',
      '& .MuiButton-root': {
        color: theme.palette.tertiary.accent,
        padding: 0,
      },
    },
    '&:hover': {
      '& .manageIcons': {
        visibility: 'visible',
      },
    },
  },
  altRow: {
    '& .manageIcons': {
      visibility: 'hidden',
      display: 'flex',
      justifyContent: 'flex-start',
      '& .MuiButton-root': {
        color: theme.palette.tertiary.accent,
        padding: 0,
      },
    },
    '&:hover': {
      '& .manageIcons': {
        visibility: 'visible',
      },
    },
  },
  deleteProviderDialog: {
    fontFamily: 'Roboto',
    fontSize: '18px',
  },
  deleteProviderActions: {
    padding: theme.spacing(2),
  },
  deleteProviderContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(1),
    textAlign: 'center',
    fontSize: '22px',
    padding: theme.spacing(2),
    fontWeight: '300',
    '& h2': {
      color: theme.palette.primary.main,
      fontSize: '30px',
      fontWeight: '400',
    },
    '& span': {
      padding: theme.spacing(2),
    },
  },
  descriptorText: {
    fontSize: '18px',
    fontWeight: '300',
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  marketReportChart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexBasis: '31%',
    minWidth: 300,
    marginTop: theme.spacing(2),
    overflow: 'hidden',
  },
  marketReportLinks: {
    display: 'flex',
    position: 'relative',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  marketReportLinkContainer: {
    overflow: 'hidden',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  marketReportLink: {
    paddingTop: theme.spacing(6),
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    fontSize: '14px',
  },
  chartHeader: {
    textDecoration: 'none',
    color: theme.palette.tertiary.accent,
    fontSize: '18px !important',
    height: '58px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  noProvidersAvailable: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    background: 'white',
    width: '100%',
    height: '100%',
    fontSize: '13px',
    fontStyle: 'italic',
    color: '#9E9E9E',
    fontFamily: 'Roboto',
    width: '100%',
    textAlign: 'center',
    '& > *': {
      marginTop: theme.spacing(2),
    },
    paddingBottom: theme.spacing(2),
  },
  disclaimerContent: {
    color: '#9E9E9E',
    fontStyle: 'italic',
    fontSize: 13,
  },
});

const columns = (self) => [
  {
    label: '',
    key: 'clients',
    style: { width: 14 },
    sortable: false,
    showFilter: false,
    format: (clients, row) => {
      if (Array.isArray(clients) && clients.length > 0) {
        return <AffiliatedIcon key={row.id} type={'Provider'} />;
      }
      return undefined;
    },
  },
  {
    label: 'Last Name',
    key: 'last_name',
    style: { width: 125 },
  },
  {
    label: 'First Name',
    key: 'first_name',
    style: { width: 125 },
  },
  {
    label: 'Location',
    key: 'location.name',
  },
  {
    label: 'City',
    key: 'location.city',
  },
  {
    label: 'State',
    key: 'location.state',
    style: { width: 75 },
  },
  {
    label: '',
    key: 'tags',
    style: { width: 50 },
    sortable: false,
    showFilter: false,
    format: (_clients, row) => {
      return (
        <span className='manageIcons' key={`manageRow-${row.id}`}>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              self.onRemoveProvider(row);
              return false;
            }}
          >
            <Icon>cancel</Icon>
          </Button>
        </span>
      );
    },
  },
];

class TagDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      findProviderOpen: false,
      stickyTab: undefined,
      hasNamespaces: true,
      deleteProviderId: undefined, // also shows the modal
      before: false,
      mostRecent: [],
      query: {
        attributes: ['id', 'last_name', 'first_name', 'location_id'],
        include: [
          {
            association: 'location',
            attributes: ['id', 'name', 'city', 'state'],
            required: false,
          },
          {
            association: 'clients',
            attributes: ['id'],
            through: { attributes: [] },
            required: false,
            paranoid: false,
            duplicating: false,
            where: {
              id: props.clientId,
            },
          },
          {
            association: 'tags',
            attributes: [],
            duplicating: false,
            required: true,
            where: {
              id: this.props.tagId,
            },
          },
        ],
        limit: 50,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    this.fetchProviders();
    if (!this.props.currentTag) {
      this.fetchTag();
    }
    this.fetchServicelines();
    this.fetchCharts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.tagNamespaces !== prevProps.tagNamespaces &&
      StoreUtil.isLoaded(this.props.tagNamespaces) &&
      this.props.tagNamespaces?.data.length === 0
    ) {
      this.setState({ hasNamespaces: false });
    }
    if (this.props.tagId !== prevProps.tagId && this.props.tagId) {
      this.fetchTag();
      this.fetchProviders();
      this.fetchCharts();
    }
    if (
      this.props.currentTag &&
      this.props.currentTag.client_id !== this.props.clientId
    ) {
      this.props.router.navigate('/labels', { replace: true });
    }
    if (prevState.mostRecent !== this.state.mostRecent) {
      this.fetchProvidersByProjectedPatients(this.state.mostRecent);
      this.fetchOrgsByProjectedPatients(this.state.mostRecent);
      this.fetchSharedAffiliationByProvider(this.state.mostRecent);
      this.fetchSharedAffiliationByServiceline(this.state.mostRecent);
    }
  }

  render() {
    const {
      providers,
      organizations,
      classes,
      currentTag,
      currentTab,
      isMobile,
      isEarlyMobile,
    } = this.props;
    const { deleteProvider, findProviderOpen, query, stickyTab, appbarHeight } =
      this.state;
    const ns = currentTag?.namespace;
    if (StoreUtil.isLoading(this.props.tagNamespaces)) {
      return <LoadingView />;
    }
    let mostRecentServiceLine = this.state.mostRecent.find(
      (c) => c.field === 'serviceLine',
    );
    let mostRecentBefore = this.state.mostRecent.find(
      (c) => c.field === 'before',
    );
    let servicelineId = mostRecentServiceLine
      ? mostRecentServiceLine?.value
      : ServiceLineUtil.getDefault();
    if (servicelineId === 'all') {
      servicelineId = ServiceLineUtil.getDefault();
    }
    const serviceline = ServiceLineUtil.findServiceLine(
      this.props.servicelines?.data ?? [],
      Array.isArray(servicelineId) ? servicelineId[0] : servicelineId,
    );
    const isBefore = mostRecentBefore?.value ?? false;
    const tabToChild = {
      providerflow: ns?.providers_drop_down ? (
        <LabelProviderFlow tag={currentTag} />
      ) : (
        <Card>
          <CardContent>
            <span>This label type does not support providers.</span>
          </CardContent>
        </Card>
      ),
      providers: ns?.providers_drop_down ? (
        <>
          {this.props.hasClaimsAccess && (
            <Card className={classes.card}>
              <CardContent>
                <div className={classes.tableHeader}>
                  <TitleTooltip title='Market Data' />
                  <div className={classes.spacer} />
                </div>
                <div className={classes.marketReportLinks}>
                  <div className={classes.marketReportChart}>
                    <div className={classes.chartHeader}>
                      <Link
                        className={classes.chartHeader}
                        key='tag-proj-patients'
                        to={`/claims?claimsType=providers&patientType=all_patients&isMarket=false&market=&tag=${this.props.currentTag?.id}&serviceline=${serviceline?.id}&claimSetting=0&specialties=`}
                      >
                        Providers By Projected Patients in{' '}
                        {serviceline?.name ?? 'All Service Lines'}
                      </Link>
                    </div>
                    <div>
                      <ProviderProjectedPatientsChart
                        legend={false}
                        patientType='patients'
                      />
                    </div>
                    <div className={classes.marketReportLinkContainer}>
                      <Link
                        className={classes.marketReportLink}
                        key='tag-proj-patients'
                        to={`/claims?claimsType=providers&patientType=all_patients&isMarket=false&market=&tag=${this.props.currentTag?.id}&serviceline=${serviceline?.id}&claimSetting=0&specialties=`}
                      >
                        Go To Market Data
                      </Link>
                    </div>
                  </div>

                  <div className={classes.marketReportChart}>
                    <div className={classes.chartHeader}>
                      <Link
                        className={classes.chartHeader}
                        key='tag-top-shared-patients'
                        to={`/claims?report=shared_affiliation&isMarket=false&market=&tag=${this.props.currentTag?.id}&groupByServicelines=false&serviceline=all&before=${isBefore}&affiliation=all&patientType=all_patients`}
                      >
                        Shared Patient Affiliation{' '}
                        {isBefore ? 'Before' : 'After'} Provider in All Service
                        Lines
                      </Link>
                    </div>
                    <div>
                      <SharedAffiliationChart
                        groupByServicelines={false}
                        hasChildren={false}
                        serviceline={'all'}
                        before={isBefore}
                        isMarket={false}
                        onServicelineClick={() => {}}
                        legend={false}
                      />
                    </div>
                    <div className={classes.marketReportLinkContainer}>
                      <Link
                        className={classes.marketReportLink}
                        key='tag-top-shared-patients'
                        to={`/claims?report=shared_affiliation&isMarket=false&market=&tag=${this.props.currentTag?.id}&groupByServicelines=false&serviceline=all&before=${isBefore}&affiliation=all&patientType=all_patients`}
                      >
                        Go To Market Data
                      </Link>
                    </div>
                  </div>

                  <div className={classes.marketReportChart}>
                    <div className={classes.chartHeader}>
                      <Link
                        className={classes.chartHeader}
                        key='tag-shared-patients-service-line'
                        to={`/claims?report=shared_affiliation&isMarket=false&market=&tag=${this.props.currentTag?.id}&groupByServicelines=true&serviceline=all&before=${isBefore}&affiliation=all&patientType=all_patients`}
                      >
                        Shared Patients Affiliation by Service Line
                      </Link>
                    </div>
                    <div>
                      <SharedAffiliationChart
                        groupByServicelines={true}
                        hasChildren={false}
                        serviceline={'all'}
                        before={isBefore}
                        isMarket={false}
                        onServicelineClick={() => {}}
                        limit={5}
                        legend={false}
                      />
                    </div>
                    <div className={classes.marketReportLinkContainer}>
                      <Link
                        className={classes.marketReportLink}
                        key='tag-shared-patients-service-line'
                        to={`/claims?report=shared_affiliation&isMarket=false&market=&tag=${this.props.currentTag?.id}&groupByServicelines=true&serviceline=all&before=${isBefore}&affiliation=all&patientType=all_patients`}
                      >
                        Go To Market Data
                      </Link>
                    </div>
                  </div>
                  {StoreUtil.isLoaded(providers) &&
                    providers.data?.rows?.length < 1 && (
                      <span className={classes.noProvidersAvailable}>
                        <Typography className={classes.disclaimerContent}>
                          Add Providers to this label to see market data.
                        </Typography>
                      </span>
                    )}
                </div>
              </CardContent>
            </Card>
          )}
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.tableHeader}>
                <TitleTooltip title='Providers' />
                <div className={classes.spacer} />
                <SmallIconTextButton
                  icon='add'
                  text='Provider'
                  onClick={this.onAddProvider}
                />
              </div>
              <DynamicTable
                columns={columns(this)}
                data={providers}
                ContainerType={React.Fragment}
                query={query}
                onQueryChange={this.onQueryChange}
                onRowClick={this.onProviderRowClick}
                getRowHref={(row) => `/providers/${row.id}`}
                classes={{
                  regRow: classes.regRow,
                  altRow: classes.altRow,
                }}
              />
            </CardContent>
          </Card>
        </>
      ) : (
        <Card>
          <CardContent>
            <span>This label type does not support providers.</span>
          </CardContent>
        </Card>
      ),
      activity_notes: ns?.activity_notes_drop_down ? (
        <ProviderCommentsByTag tag={currentTag} />
      ) : (
        <Card>
          <CardContent>
            <span>This label type does not support activity notes.</span>
          </CardContent>
        </Card>
      ),
      organizations: ns?.organizations_drop_down ? (
        <>
          {this.props.hasClaimsAccess && (
            <Card className={classes.card}>
              <CardContent>
                <div className={classes.tableHeader}>
                  <TitleTooltip title='Market Data' />
                  <div className={classes.spacer} />
                </div>
                <div className={classes.marketReportLinks}>
                  <div className={classes.marketReportChart}>
                    <div className={classes.chartHeader}>
                      <Link
                        className={classes.chartHeader}
                        key='tag-proj-patients'
                        to={`/claims?claimsType=organizations&patientType=all_patients&isMarket=false&market=&tag=${this.props.currentTag?.id}&serviceline=${serviceline?.id}&claimSetting=0&specialties=`}
                      >
                        Organizations By Projected Patients in{' '}
                        {serviceline?.name ?? 'All Service Lines'}
                      </Link>
                    </div>
                    <div>
                      <OrgProjectedPatientsChart
                        legend={false}
                        patientType='patients'
                      />
                    </div>
                    <div className={classes.marketReportLinkContainer}>
                      <Link
                        className={classes.marketReportLink}
                        key='tag-proj-patients'
                        to={`/claims?claimsType=organizations&patientType=all_patients&isMarket=false&market=&tag=${this.props.currentTag?.id}&serviceline=${serviceline?.id}&claimSetting=0&specialties=`}
                      >
                        Go To Market Data
                      </Link>
                    </div>
                  </div>
                  {StoreUtil.isLoaded(organizations) &&
                    organizations.data?.rows?.length < 1 && (
                      <span className={classes.noProvidersAvailable}>
                        <Typography className={classes.disclaimerContent}>
                          Add Organizations to this label to see market data.
                        </Typography>
                      </span>
                    )}
                </div>
              </CardContent>
            </Card>
          )}
          <OrganizationsByTag tag={currentTag} />
        </>
      ) : (
        <Card>
          <CardContent>
            <span>This label type does not support organizations.</span>
          </CardContent>
        </Card>
      ),
    };
    let children = tabToChild[currentTab];
    if (!this.state.hasNamespaces) {
      children = (
        <Card>
          <CardContent>
            No label types were found for the current client. Please contact
            Doctivity support to add label types and labels.
          </CardContent>
        </Card>
      );
    }
    return (
      <>
        <Helmet defer={false}>
          <title>
            {ns?.name ?? ''}: {currentTag?.name ?? ''}
          </title>
        </Helmet>
        <div className={classes.header}>
          <TagSelector
            darkBackground={true}
            tagId={this.props.tagId}
            onChange={this.handleTagChange}
          />
          <div className={classes.headerRight}>
            <ContainedSmallIconButton
              text='Manage Labels'
              onClick={() => this.props.router.navigate('/label_types')}
            />
          </div>
        </div>
        <FixedHeader
          top={isMobile ? 50 : 0}
          onChangeHeaderHeight={this.onChangeHeaderHeight}
        >
          <div className={classes.stickyContainer}>
            <div className={classes.tagHeader}>
              <Icon className={classes.headerIcon}>sell</Icon>
              <div className={classes.tagName}>
                <Typography className={`${classes.headerItem}`}>
                  {currentTag?.namespace?.name}
                </Typography>
                <Typography className={classes.headerTitle} style={{}}>
                  {currentTag?.name}
                </Typography>
              </div>
              <Divider
                style={{
                  borderColor: stickyTab === false ? '#0000001f' : '#EDEDED',
                  transition: 'borderColor 0.1s ease-in',
                }}
              />
            </div>
            <StickyHeader
              headerTop={isMobile || isEarlyMobile ? appbarHeight : 0}
              className={classes.stickyHeader}
              onChangeIsSticky={(stickyTab) => this.setState({ stickyTab })}
            >
              {this.state.stickyTab !== false && (
                <Tabs
                  variant='scrollable'
                  className={classes.tabs}
                  TabIndicatorProps={{ className: classes.indicator }}
                  value={currentTab}
                  onChange={this.onTabChange}
                >
                  <Tab label='Providers' value='providers' disableRipple />
                  {this.props.user.is_admin && (
                    <Tab
                      label='Provider Flow'
                      value='providerflow'
                      disableRipple
                    />
                  )}
                  <Tab
                    label='Organizations'
                    value='organizations'
                    disableRipple
                  />
                  <Tab
                    label='Activity Notes'
                    value='activity_notes'
                    disableRipple
                  />
                </Tabs>
              )}
              <Divider />
            </StickyHeader>
          </div>
        </FixedHeader>
        <div className={classes.container}>{children}</div>
        <div style={{ height: '64px' }} />
        {findProviderOpen && (
          <FindProviderDialog
            clientId={this.props.clientId}
            open={findProviderOpen}
            onMultiSelect={this.onProvidersMultiSelected}
            selectedLabelId={this.props.tagId}
            selectedProviderIds={providers.data.rows ? providers.data.rows.map((p) => p.id) : null}
          />
        )}
        {deleteProvider && (
          <Dialog className={classes.deleteProviderDialog} open={true}>
            <DialogContent className={classes.deleteProviderContent}>
              <h2>Remove Provider</h2>
              <Divider />
              <span>
                Are you sure you want to remove {deleteProvider.last_name}
                ,&nbsp;{deleteProvider.first_name}&nbsp;from&nbsp;
                {currentTag?.name}?
              </span>
              <span className={classes.descriptorText}>
                This will immediately delete the provider from the label type.
                This action cannot be undone.
              </span>
            </DialogContent>
            <DialogActions className={classes.deleteProviderActions}>
              <Button
                onClick={() => {
                  this.setState({ deleteProvider: undefined });
                }}
              >
                No, Cancel
              </Button>
              <Button
                variant='contained'
                onClick={() => {
                  this.removeProvider(deleteProvider, currentTag);
                  this.setState({ deleteProvider: undefined });
                }}
              >
                Yes, Remove Provider
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }

  fetchProviders() {
    const { query } = this.state;

    const tagsInclude = query.include.find((i) => i.association === 'tags');
    tagsInclude.where = { id: this.props.tagId };

    const clientsInclude = query.include.find(
      (i) => i.association === 'clients',
    );
    clientsInclude.where = { id: this.props.clientId };
    const location = query.include.find((i) => i.association === 'location');
    if (Object.keys(location?.where ?? {}).length > 0) {
      location.required = true;
    } else {
      location.required = false;
    }
    this.props.dispatch(queryProviders(query));
  }

  fetchTag() {
    if (this.props.tagId) {
      this.props.dispatch(getTag(this.props.tagId, this.props.clientId));
    }
  }

  handleTagChange = (newTag) => {
    if (newTag) {
      let tab = '';
      switch (this.props.currentTab) {
        case 'activity_notes':
          tab = '/notes';
          break;
        case 'organizations':
          tab = '/organizations';
          break;
        case 'providerflow':
          tab = '/providerflow';
          break;
      }
      this.props.router.navigate(`/labels/${newTag.id}${tab}`, {
        replace: true,
      });
    } else {
      console.log('no tab found?');
    }
  };

  onProvidersMultiSelected = (rows) => {
    if (!rows || !Array.isArray(rows) || rows.length < 1) {
      this.setState({ findProviderOpen: false });
      return;
    }
    const providerIds = new Set(rows.map((t) => t.id));
    const currentIds = new Set(
      this.props.providers.data?.rows?.map((r) => r.provider_id),
    );
    currentIds.forEach((id) => providerIds.delete(id));
    const {
      dispatch,
      currentTag: { id },
    } = this.props;
    if (providerIds.size > 0) {
      dispatch(
        upsertProviderTag(
          [...providerIds].map((p) => ({ tag_id: id, provider_id: p })),
          queryProviders(this.state.query),
        ),
      );
    }
    this.setState({ findProviderOpen: false });
    this.fetchTag();
  };
  onAddProvider = () => {
    if (this.props.currentTag?.namespace?.providers_drop_down) {
      this.setState({ findProviderOpen: true, findOrganizationOpen: false });
    } else {
      alert('This label does not support providers');
    }
  };
  onRemoveProvider = (provider) => {
    this.setState({ deleteProvider: provider });
  };

  removeProvider = (provider, tag) => {
    if (this.props.dispatch) {
      this.props.dispatch(
        deleteProviderTag(
          [
            {
              provider_id: provider.id,
              tag_id: tag.id,
            },
          ],
          provider.id,
          queryProviders(this.state.query),
        ),
      );
    }
  };

  onTabChange = (_, tab) => {
    switch (tab) {
      case 'activity_notes': {
        if (this.props.currentTag) {
          this.props.router.navigate(
            `/labels/${this.props.currentTag?.id}/notes`,
            {
              replace: true,
            },
          );
        }
        break;
      }
      case 'organizations': {
        this.props.router.navigate(
          `/labels/${this.props.currentTag?.id}/organizations`,
          {
            replace: true,
          },
        );
        break;
      }
      case 'providerflow': {
        this.props.router.navigate(
          `/labels/${this.props.currentTag?.id}/providerflow`,
          {
            replace: true,
          },
        );
        break;
      }
      default: {
        this.props.router.navigate(`/labels/${this.props.currentTag?.id}`, {
          replace: true,
        });
      }
    }
  };
  onQueryChange = (query) => {
    if (query.order) {
      let idIdx = -1;
      query.order.forEach((condition, idx) => {
        if (condition[0] === 'id') {
          idIdx = idx;
        }
      });
      if (idIdx > -1) {
        query.order.splice(idIdx, 1);
      }
    }
    this.setState({ query }, this.fetchProviders.bind(this));
  };

  onProviderRowClick = (row) => {
    this.props.router.navigate(`/providers/${row.id}`);
  };
  fetchOrgsByProjectedPatients = (mostRecent) => {
    try {
      let mostRecentServiceLine = mostRecent.find(
        (c) => c.field === 'serviceLine',
      );
      let mostRecentTaxonomySetting = mostRecent.find(
        (c) => c.field === 'taxonomies',
      );
      const type = ClaimQueryType.CLAIMS_PER_ORGANIZATION;
      const sl = mostRecentServiceLine
        ? mostRecentServiceLine.value
        : ServiceLineUtil.getDefault();
      const options = {
        type,
        filter: {
          serviceline: sl === 'all' ? ServiceLineUtil.getDefault() : sl,
          codes: null,
          codes_type: null,
          tag: { id: this.props.tagId },
          location_type: undefined,
          patient_type: 'ALL_PATIENTS',
          taxonomies: mostRecentTaxonomySetting?.value ?? [],
          affiliated_client_id: this.props.clientId,
        },
        opts: {
          limit: 5,
        },
      };
      if (!options.filter.market && !options.filter.tag) {
        return;
      }
      this.props.dispatch(
        loadAnalytics({
          ...options,
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };

  fetchProvidersByProjectedPatients = (mostRecent) => {
    try {
      let mostRecentServiceLine = mostRecent.find(
        (c) => c.field === 'serviceLine',
      );
      let mostRecentTaxonomySetting = mostRecent.find(
        (c) => c.field === 'taxonomies',
      );
      const type = ClaimQueryType.CLAIMS_PER_PROVIDER;
      const sl = mostRecentServiceLine
        ? mostRecentServiceLine.value
        : ServiceLineUtil.getDefault();
      const options = {
        type,
        filter: {
          serviceline: sl === 'all' ? ServiceLineUtil.getDefault() : sl,
          codes: null,
          codes_type: null,
          tag: { id: this.props.tagId },
          location_type: undefined,
          patient_type: 'ALL_PATIENTS',
          taxonomies: mostRecentTaxonomySetting?.value ?? [],
          affiliated_client_id: this.props.clientId,
        },
        opts: {
          limit: 5,
        },
      };
      if (!options.filter.market && !options.filter.tag) {
        return;
      }
      this.props.dispatch(
        loadAnalytics({
          ...options,
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };

  fetchSharedAffiliationByProvider = (mostRecent) => {
    const { clientId } = this.props;
    let mostRecentBefore = mostRecent.find((c) => c.field === 'before');
    this.props.dispatch(
      loadAnalytics({
        type: 'CLAIMS_SHARED_AFFILIATION',
        filter: {
          serviceline: undefined,
          before: mostRecentBefore?.value ?? false,
          client_id: clientId,
          group_by_serviceline: false,
          tag: { id: this.props.tagId },
          patient_type: PatientType.ALL_PATIENTS,
        },
        opts: {
          limit: 5,
        },
      }),
    );
  };
  fetchSharedAffiliationByServiceline = (mostRecent) => {
    const { clientId } = this.props;
    let mostRecentBefore = mostRecent.find((c) => c.field === 'before');
    this.props.dispatch(
      loadAnalytics({
        type: 'CLAIMS_SHARED_AFFILIATION_BY_SERVICELINE',
        filter: {
          serviceline: undefined,
          before: mostRecentBefore?.value ?? false,
          client_id: clientId,
          group_by_serviceline: true,
          tag: { id: this.props.tagId },
          patient_type: PatientType.ALL_PATIENTS,
        },
      }),
    );
  };

  fetchMostRecentQuery = async () => {
    const savedCriteria = await axiosInstance.get('/UsersSavedCriteria', {
      params: {
        opts: {
          clientId: this.props.clientId,
          criteriaTypeId: 1,
        },
      },
    });
    if (savedCriteria.data && savedCriteria.data.length > 0) {
      let mostRecent = (
        typeof savedCriteria.data[0].criteria_json === 'string'
          ? JSON.parse(savedCriteria.data[0].criteria_json)
          : savedCriteria.data[0].criteria_json
      )?.criteria;
      this.setState({ mostRecent });
      return mostRecent;
    }
  };

  fetchCharts = async () => {
    await this.fetchMostRecentQuery();
  };

  fetchServicelines = () => {
    this.props.dispatch(listClaimsServicelines());
  };
}

TagDetailsPage.propTypes = {
  user: PropTypes.object,
  clientId: PropTypes.number.isRequired,
  hasClaimsAccess: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  providers: PropTypes.object.isRequired,
  organizations: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  tagId: PropTypes.number,
  currentTag: PropTypes.object,
  currentTab: PropTypes.string,
  tagNamespaces: PropTypes.object,
  servicelines: PropTypes.object,
  appbarHeight: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isEarlyMobile: PropTypes.bool.isRequired,
};

const URL_TO_TAB = {
  notes: 'activity_notes',
  organizations: 'organizations',
  providerflow: 'providerflow',
};

function mapStateToProps(state, props) {
  const { router } = props;
  const tagId = parseInt(router.params.tagId, 10);
  return {
    user: state.user,
    providers: StoreUtil.get(state.providerTags, 'providers'),
    organizations: StoreUtil.get(state.analytics, 'CLAIMS_PER_ORGANIZATION'),
    clientId: state.app.selectedClient,
    hasClaimsAccess:
      (state.clients?.list?.data ?? []).find(
        (c) => c.id === parseInt(state.app.selectedClient),
      )?.has_claims_access ?? false,
    currentTab: URL_TO_TAB[router.params.tab] ?? 'providers',
    currentTag: Number.isNaN(tagId)
      ? undefined
      : StoreUtil.get(state.tags, StoreUtil.COMMON_ITEM, tagId)?.data,
    tagId,
    tagNamespaces: StoreUtil.get(state.tagNamespaces, StoreUtil.COMMON_LIST),
    servicelines: state.claims && state.claims.servicelines_list,
  };
}

const styled = withStyles(styles)(TagDetailsPage);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(withAppbarHeight(withIsMobile(connected)));
export { routed as TagDetailsPage };
