import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Helmet } from 'react-helmet';
import { Icon, Typography, Tooltip } from '@mui/material';

import {
  StoreUtil, SpecialtyUtil
} from 'doctivity-shared/utils';
import { withRouter } from 'utils';

import {
  DynamicTable,
  RVUSparkline,
  ProvidersTabs,
} from 'components';

import { queryTaxonomyCodes } from 'store/actions/taxonomyActions';
import {
  loadAnalytics,
} from 'store/actions/analyticsActions';

const styles = (theme) => ({
  container: {
    backgroundColor: theme.palette.primary.accent,
  },
  affiliatedIcon: {
    fontSize: 14,
    verticalAlign: 'text-top',
    color: theme.palette.secondary.main,
  },
  header: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  headerTitle: {
    color: '#FFFFFF',
    fontSize: 24,
    paddingRight: 30,
  },
});

class AffiliatedProvidersPage extends React.Component {
  constructor(props) {
    super(props);

    // this controls which columns are displayed and how they are looked up in data
    this.columns = [
      {
        label: '',
        key: 'clients',
        style: { width: 14 },
        sortable: false,
        showFilter: false,
        format: () => {
          return (
            <Tooltip
              placement='bottom-start'
              title={
                <Typography
                  style={{
                    fontSize: 13,
                    fontWeight: 200,
                  }}
                >
                  Affiliated Provider
                </Typography>
              }
            >
              <div
                style={{
                  width: 14,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Icon className={props.classes.affiliatedIcon}>hub</Icon>
              </div>
            </Tooltip>
          );
        },
      },
      {
        label: 'Last Name',
        key: 'last_name',
        fulltext: true,
        style: { minWidth: 100 },
      },
      {
        label: 'First Name',
        key: 'first_name',
        fulltext: true,
        style: { minWidth: 100 },
      },
      {
        label: 'NPI',
        key: 'npi',
        style: { minWidth: 140, verticalAlign: 'top' },
      },
      {
        label: 'Specialty',
        key: 'taxonomy.classification',
        filterFields: ['primary_taxonomy'],

        format: (classification, row) => {
          return SpecialtyUtil.getNameFromAffiliatedTaxonomy(row);
        },
        specialtySearch: true
      },


      {
        label: 'City',
        key: 'location.city',
        format: (value, row) => row['location.city'] || '',
        fulltext: true,
      },
      {
        label: 'State',
        key: 'location.state',
        style: { width: 50 },
        filterExact: true,
        autoCapitalize: true,
        format: (value, row) => row['location.state'] || '',
      },
      {
        label: 'RVUs',
        key: 'total_rvu',
        style: { width: 100, verticalAlign: 'top' },
        showFilter: false,
        format: (value) => Number(value).toLocaleString(),
      },
      {
        label: 'Trend',
        key: 'id',
        format: (id) => {
          if (id) {
            return <RVUSparkline providerId={id} />;
          }
          return 'nope';
        },
        style: { width: 60, verticalAlign: 'top' },
        sortable: false,
        showFilter: false,
      },
    ];

    this.state = {
      query: {
        where: {},
        order: [
          ['total_rvu', 'DESC'],
          ['last_name', 'ASC'],
        ],
        include: [
          {
            attributes: ['id', 'city', 'state'],
            association: 'location',
          },
        ],
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    const { providers } = this.props;

    let values = JSON.parse(sessionStorage.getItem('selectedSpecialties'));

    if (values && values.length > 0) {
      this.fetchSpecialties(values);
    } else {
      this.taxonomyCodes = [];
    }

    // check for session storage
    const oldQuery = sessionStorage.getItem('affiliatedProvidersTableQuery')
    if (oldQuery) {
      this.onQueryChange(JSON.parse(oldQuery))
    }
    if (StoreUtil.needsLoadNoCache(providers)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    // is object getting edited?
    if (this.props.app.selectedClient !== prevProps.app.selectedClient) {
      this.fetchData();
    }
  }

  onQueryChange = (query) => {
    if (query.where && Object.keys(query.where).length === 0) {
      delete query.where;
    }
    sessionStorage.setItem('affiliatedProvidersTableQuery', JSON.stringify(query))
    this.setState({ query }, this.fetchData.bind(this));
  };


  fetchData() {
    const { dispatch, app } = this.props;

    const { query } = this.state;

    dispatch(
      loadAnalytics({
        type: 'AFFILIATED_PROVIDERS_WITH_RVUS',
        filter: {
          where: query.where,
          client_id: app.selectedClient,
          from: app.dateRange.start,
          to: app.dateRange.end,
          limit: query.limit,
          include: query.include,
          offset: query.offset,
          order: query.order,
        },
      })
    );
  }

  fetchSpecialties(values) {
      const { dispatch } = this.props;
  
      let queryCodes = {
        where: { code: values},
        order: [['code', 'ASC']],
        limit: 10000,
        offset: 0,
      }
  
      let qryCodes = queryTaxonomyCodes({
        ...queryCodes,
      });
  
      dispatch(
        qryCodes
      );
    }

  render() {
    const { classes, providers, taxonomyCodes } = this.props;

    const { query } = this.state;

    return (
      <div className={classes.container}>
      <Helmet defer={false}>
      <title>Providers</title>
      </Helmet>
      <div className={classes.header}>
        <Typography className={classes.headerTitle}>Providers</Typography>

      </div>

        <ProvidersTabs tab='affiliated_providers' />
        <div className={classes.container}>
          <DynamicTable
            header='Doctivity Providers'
            showFilters={true}
            columns={this.columns}
            noDataMessage='No AFFILIATED providers found.'
            query={query}
            data={providers}
            taxonomyCodes={taxonomyCodes}
            onQueryChange={this.onQueryChange}
            getRowHref={this.getRowHref}
          />
        </div>
      </div>
    );
  }

  getRowHref = (row) => {
    return `/providers/${row.id}`;
  };
}

AffiliatedProvidersPage.propTypes = {
  router: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  providers: PropTypes.object.isRequired,
  classes: PropTypes.object,
  app: PropTypes.object,
  taxonomyCodes: PropTypes.object.isRequired,  
};

function mapStateToProps(state) {
  const { analytics, app, taxonomyCodes } = state;

  return {
    app,
    providers: StoreUtil.get(analytics, 'AFFILIATED_PROVIDERS_WITH_RVUS'),
    taxonomyCodes: StoreUtil.get(taxonomyCodes, StoreUtil.COMMON_TABLE)
  };
}

const styled = withStyles(styles)(AffiliatedProvidersPage);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as AffiliatedProvidersPage };
