import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { Typography, Button, Icon } from '@mui/material';

import { StoreUtil, PhoneUtil, DateUtil } from 'doctivity-shared/utils';
import { withRouter } from 'utils';
import { DynamicTable, FindProviderDialog,   AffiliatedIcon, } from 'components';

import { queryLocationProviders } from 'store/actions/locationsActions';
import { upsertProvider } from 'store/actions/providersActions';
import { ConfirmAddProviderDialog } from 'locations';

const styles = (theme) => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

// this controls which columns are displayed and how they are looked up in data
const columns = [
   {
      label: '',
      sortable: false,
      showFilter: false,
      key: 'clients.id',
      type: 'bool',
      format: (value, row) =>{
        return row?.clients[0]?.id > 0 ? <AffiliatedIcon /> : ''
      },
      style: { width: 16 },
    },
  {
    label: 'Last Name',
    key: 'last_name',
    fulltext: true,
    style: { minWidth: 100 },
  },
  {
    label: 'First Name',
    key: 'first_name',
    fulltext: true,
    style: { minWidth: 100 },
  },
  {
    label: 'Specialty',
    key: 'taxonomy.specialization',
  },
  {
    label: 'NPI',
    key: 'npi',
  },
  {
    label: 'Phone',
    key: 'phone',
    format: (phone) => PhoneUtil.formatNumber(phone),
  },
];

class LocationProviders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      linkProviderOpen: false,
      confirmAddProviderData: undefined,
      query: {
        where: {},
        attributes: ['id', 'first_name', 'last_name', 'npi', 'phone'],
        include: [
          {
            association: 'location',
            where: {
              id: props.location.id,
            },
            required: true,
          },
          { association: 'taxonomy' },
          {
            association: 'clients',
            attributes: ['id'],
            required: false,
            duplicating: false,
            through: { attributes: [],
            where: {
              ClientId: props.clientId,
            }},
          },
        ],
        
        order: [['last_name', 'ASC']],
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    const { providers } = this.props;

    if (StoreUtil.needsLoadNoCache(providers)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { location, providers, editingProviders } = this.props;

    if (!prevProps.location || location.id !== prevProps.location.id) {
      this.fetchData();
    }

    // is object getting edited?
    if (editingProviders !== prevProps.editingProviders) {
      if (StoreUtil.hasSavedSinceLoad(editingProviders, providers)) {
        this.fetchData();
      }
    }
  }

  fetchData() {
    const { location } = this.props;

    if (location && location.id) {
      this.props.dispatch(
        queryLocationProviders(location.id, this.state.query, this.props.clientId)
      );
    }
  }

  render() {
    const { classes, providers, clientId, location } = this.props;

    const { query, linkProviderOpen, confirmAddProviderData } = this.state;

    return (
      <>
        <DynamicTable
          header={
            <div className={classes.headerContainer}>
              <Typography
                component='h2'
                variant='h6'
                color='primary'
                gutterBottom
              >
                Providers
              </Typography>
              <div className={classes.header} />

              <Button
                color='primary'
                size='small'
                onClick={this.onOpenLinkProvider}
              >
                <Icon className={classes.leftIcon}>add</Icon>
                Link Provider
              </Button>
            </div>
          }
          showFilters={true}
          noDataMessage='No providers in this location.'
          columns={columns}
          query={query}
          data={providers}
          onQueryChange={this.onQueryChange}
          getRowHref={this.getRowHref}
        />
        {linkProviderOpen && (
          <FindProviderDialog
            clientId={clientId}
            open={linkProviderOpen}
            onSelect={this.onProviderSelected}
          />
        )}
        {confirmAddProviderData && (
          <ConfirmAddProviderDialog
            open={!!confirmAddProviderData}
            locationData={location}
            provider={confirmAddProviderData}
            onConfirm={this.onConfirmAddProvider}
            onClose={this.closeConfirmAddProviderDialog}
          />
        )}
      </>
    );
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData.bind(this));
  };

  getRowHref = (row) => {
    return `/providers/${row.id}`;
  };

  onOpenLinkProvider = () => {
    this.setState({
      linkProviderOpen: true,
    });
  };

  onCloseLinkProvider = () => {
    this.setState({
      linkProviderOpen: false,
    });
  };

  onConfirmAddProvider = (provider) => {
    const { location, dispatch } = this.props;

    if (provider && location && location.id) {
      if (provider.location_id !== location.id) {
        provider.location_updated_at = DateUtil.formatNowForDB();
      }
      dispatch(upsertProvider({ ...provider, location_id: location.id }));
    }
    this.onCloseLinkProvider();
  };

  closeConfirmAddProviderDialog = () => {
    this.setState({ confirmAddProviderData: undefined });
  };

  onProviderSelected = (provider) => {
    const { location } = this.props;

    if (provider && location && location.id) {
      this.setState({ confirmAddProviderData: provider });
    } else {
      // cancel sends a null provider, just close
      this.onCloseLinkProvider();
    }
  };
}

LocationProviders.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  providers: PropTypes.object.isRequired,
  classes: PropTypes.object,
  location: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  editingProviders: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    clientId: state.app.selectedClient,
    providers: StoreUtil.get(state.locations, 'providers'),
    editingProviders: StoreUtil.get(
      state.providers,
      StoreUtil.COMMON_EDIT_ITEM
    ),
  };
}

const styled = withStyles(styles)(LocationProviders);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as LocationProviders };
