import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import withStyles from '@mui/styles/withStyles';
import { Button, Card, CardContent, Icon, Typography } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';

import axiosInstance from 'utils/axiosUtil';
import { LoadingView, ProviderCommentsDialog} from 'components';
import { LocationsUtil, withRouter } from 'utils';
import { DateUtil, EncounterTypeUtil, StoreUtil } from 'doctivity-shared/utils';

import {
  getProviderComment,
  upsertProviderComment,
} from 'store/actions/providerCommentsActions';
import {
  showConfirmationDialog,
} from 'store/actions/systemActions';

const styles = (theme) => ({
  container: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  card: {
    marginBottom: theme.spacing(3),
  },
  cardContent: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
  encounterType: {
    fontSize: 24,
  },
  encounterIcon: {
    fontSize: 24,
    paddingRight: 3,
    position: 'relative',
    top: 3,
    color: '#999999',
  },
  dateName: {
    '& > *': {
      marginRight: theme.spacing(3),
    },
    fontSize: 17,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 0,
  },
  headerContent: {
    '& > *': {
      marginBottom: theme.spacing(0.5),
    },
    display: 'flex',
    flexDirection: 'column',
  },
  providers: {
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  fieldTitle: {
    fontSize: 13,
  },
  providerName: {
    textDecoration: 'underline',
  },
  headerButtons: {
    display: 'flex',
    flexDirection: 'row',
  },
  needsResolved: {
    fontWeight: 400,
    fontSize: 16,
    color: '#2E93fA',
  },
  resolvedBy: {
    fontSize: 13,
    color: 'rgba(0,0,0,0.87);',
  },
  actionButton: {
    '& > *': {
      marginRight: theme.spacing(1),
    },
    fontSize: 16,
  },
  actionButtonIcon: {
    fontSize: 18,
  },
  tags: {
    fontSize: 13,
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
  tagName: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#999999',
    borderRadius: 20,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
  },
  namespace: {
    display: 'flex',
    alignItems: 'center',
  },
  namespaceName: {
    marginRight: theme.spacing(1),
  },
  commentBodyText: {
    whiteSpace: 'pre-wrap',
    fontSize: 13,
  },
});

class ProviderCommentDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dialogOpen: false, duplicate: false };
  }

  componentDidUpdate(prevProps) {
    const { comment, editComment } = this.props;
    // anytime this comment has been edited, update it
    if (
      editComment !== prevProps.editComment &&
      StoreUtil.hasSavedSinceLoad(editComment, comment)
    ) {
      this.fetchData();
    }
  }

  onDocLink = ((e,doc) => {
    axiosInstance.get(`/DocumentsDownload/${doc.ProviderCommentDocuments.document_id}`).then((response) => {
      // create a link, click it, then remove it... ugly hack
      const link = document.createElement('a');
      link.href = response.data.url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  });

  fetchData() {
    const { dispatch, router } = this.props;
    dispatch(getProviderComment(router.params.id));
  }

  renderTags(tags) {
    const { classes } = this.props;

    if (tags && tags.length) {
      // group by namespace
      const namespaces = {};
      tags.forEach((tag) => {
        if (!namespaces[tag.namespace_id]) {
          namespaces[tag.namespace_id] = {
            id: tag.namespace.id,
            name: tag.namespace.name,
            tags: [],
          };
        }
        namespaces[tag.namespace_id].tags.push([tag.id, tag.name]);
      });

      return (
        <div className={classes.tags}>
          {Object.values(namespaces).map((namespace) => (
            <div className={classes.namespace} key={namespace.id}>
              <span
                className={classes.namespaceName}
              >{`${namespace.name}: `}</span>
              {namespace.tags.map(([id, name], index) => (
                <Link key={index} to={`/labels/${id}/notes`} className={classes.tagName}>
                  {name}
                </Link>
              ))}
            </div>
          ))}
        </div>
      );
    }
    return null;
  }

  renderDocuments(documents) {
    const { classes } = this.props;

    return (
      <div className={classes.providers}>
      {documents?.length > 0 && (
       <span className={classes.fieldTitle}>Documents:</span> 
      )}
      {documents.map((document) => (
          <a onClick={(e) => this.onDocLink(e,document)} key={document}>
            <Button className={classes.providerName}>
              {`${document.name}`}
            </Button>
          </a>
      ))}
      </div>
    );
  }
  

  render() {
    const { classes, clientId, comment, editComment, user } = this.props;
    const { dialogOpen } = this.state;

    const commentData = comment.data;


    if (commentData === null || StoreUtil.isSaving(editComment)) {
      return <LoadingView />;
    }

    return (
      <>
        <Helmet defer={false}>
          <title>{`Activity Note - ${DateUtil.formatDateTimeFromDB(
            commentData.comment_date
          )}`}</title>
        </Helmet>
        <div className={classes.container}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.header}>
                <div className={classes.headerContent}>
                  {commentData.encounter_type_id && (
                    <Typography className={classes.encounterType}>
                      <Icon className={classes.encounterIcon}>
                        {EncounterTypeUtil.getIcon(
                          commentData.encounter_type_id
                        )}
                      </Icon>
                      {EncounterTypeUtil.getName(commentData.encounter_type_id)}
                    </Typography>
                  )}
                  <Typography className={classes.dateName}>
                    <span>
                      {DateUtil.formatDateTimeFromDB(commentData.comment_date)}
                    </span>
                    <span>
                      Created by {commentData.user.first_name}{' '}
                      {commentData.user.last_name}
                    </span>
                  </Typography>
                </div>
                <div className={classes.headerButtons}>
                  {!this.props.user.is_admin && (
                    <Button
                        className={classes.actionButton}
                        variant='text'
                        onClick={() => {
                          this.duplicateComment();
                        }}
                      >
                        <Icon className={classes.actionButtonIcon}>content_copy</Icon>
                        <span>Copy</span>
                    </Button>
                  )}
                  {commentData.user.id === user.id && (
                      <Button
                        className={classes.actionButton}
                        variant='text'
                        onClick={() => {
                          this.editComment();
                        }}
                      >
                        <Icon className={classes.actionButtonIcon}>edit</Icon>
                        <span>Edit</span>
                      </Button>
                  )}

                  {commentData.needs_followup &&
                    !commentData.is_resolved &&
                    commentData.user.id === user.id && (
                      <Button
                        className={classes.actionButton}
                        variant='text'
                        onClick={() => {
                          this.resolveComment(commentData);
                        }}
                      >
                        <CheckCircleOutline
                          className={classes.actionButtonIcon}
                        />
                        <span>Resolve</span>
                      </Button>
                    )}
                </div>
              </div>
              {commentData.providers?.length > 0 && (
                <div className={classes.providers}>
                  <span className={classes.fieldTitle}>Providers:</span>
                  {commentData.providers.map((provider) => (
                    <Link key={provider.id} to={`/providers/${provider.id}`}>
                      <Button className={classes.providerName}>
                        {`${provider.last_name}, ${provider.first_name}`.toUpperCase()}
                      </Button>
                    </Link>
                  ))}
                </div>
              )}
              {commentData.contacts?.length > 0 && (
                <div className={classes.providers}>
                  <span className={classes.fieldTitle}>Contacts:</span>
                  {commentData.contacts.map((contact) => (
                    <Link key={contact.id} to={`/contacts/${contact.id}`}>
                      <Button className={classes.providerName}>
                        {`${contact.last_name}, ${contact.first_name}`.toUpperCase()}
                      </Button>
                    </Link>
                  ))}
                </div>
              )}
              {commentData.locations?.length > 0 && (
                <div className={classes.providers}>
                  <span className={classes.fieldTitle}>Locations:</span>
                  {commentData.locations.map((location) => (
                    <Link
                    key={location.id}
                    to={`/locations/${location.id}`}
                  >
                    <Button className={classes.providerName}>
                      {LocationsUtil.formatFullNameAndAddress(
                        location
                      )}
                    </Button>
                  </Link>
                  ))}

                </div>
              )}
              {commentData.needs_followup && !commentData.is_resolved && (
                <div className={classes.needsResolved}>
                  {commentData.due_date && <div>
                    Needs resolved by
                    {` ${DateUtil.formatRelativeToday(commentData.due_date)}`}
                  </div>}
                </div>
              )}
              {commentData.is_resolved && (
                <div>
                  <div className={classes.needsResolved}>
                    Resolved on {DateUtil.formatDateForUser(commentData.resolved_date)}&nbsp;
                    {commentData.resolved_date && (<span className={classes.resolvedBy}>(Due {DateUtil.formatDateForUser(commentData.due_date)})</span>)}
                </div>
                </div>
              )}
              {commentData.tags && this.renderTags(commentData.tags)}
              <Typography className={classes.commentBodyText}>
                {commentData.body}
              </Typography>

              {commentData.documents && this.renderDocuments(commentData.documents)}

            </CardContent>
          </Card>
          {dialogOpen && (
            <ProviderCommentsDialog
              clientId={clientId}
              data={commentData}
              open={dialogOpen}
              onSave={this.onSave}
              onClose={this.closeDialog}
              duplicate={this.state.duplicate}
            />
          )}
        </div>
      </>
    );
  }

  editComment = () => {
    this.setState({
      dialogOpen: true,
    });
  };
  
  duplicateComment = () => {
    this.setState({
      dialogOpen: true,
      duplicate: true
    });
  };


  closeDialog = () => {
    this.setState({ dialogOpen: false });
  };

  onSave = (data) => {
    const { clientId, user, dispatch } = this.props;

    if (data.body && data.body.length > 0) {
      data.client_id = clientId;
      data.user_id = user.id;
      dispatch(upsertProviderComment(data));
    }
    this.closeDialog();
  };

  resolveComment = (comment) => {
    this.props.dispatch(
      showConfirmationDialog({
        title: 'Resolve Note',
        content: 'Are you sure you want to resolve this note?',
        confirmLabel: 'Resolve',
        onConfirm: () => {
          comment.is_resolved = true;
          this.props.dispatch(upsertProviderComment(comment));
        },
        onCancel: () => {
          
        }
      })
    );
  };
}

ProviderCommentDetailPage.propTypes = {
  app: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  classes: PropTypes.object,
  comment: PropTypes.object,
  editComment: PropTypes.object,
};

function mapStateToProps(state, props) {
  const { app, user, providerComments } = state;
  const { router } = props;
  const commentId = router.params.id;

  return {
    app,
    user,
    clientId: app.selectedClient,
    comment:
      commentId &&
      StoreUtil.get(providerComments, StoreUtil.COMMON_ITEM, commentId),
    editComment: StoreUtil.get(providerComments, StoreUtil.COMMON_EDIT_ITEM),
  };
}

const styled = withStyles(styles)(ProviderCommentDetailPage);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ProviderCommentDetailPage };
