class SpecialtyUtil {
  static getNameFromTaxonomy(taxonomy) {
    let specialty = '';
    if (taxonomy) {
      if (taxonomy.doctivity_name) {
        specialty = taxonomy.doctivity_name;
      } else {
        if (taxonomy.specialization) {
          specialty = taxonomy.specialization;
        } else if (taxonomy.classification) {
          specialty = taxonomy.classification;
        }
      }
    }
    return specialty;
  }

  static getColumnValueFromTaxonomy(taxonomy, colname) {
    let retVal = '';
    if (taxonomy) {
      if (colname==='classification') {
        retVal = taxonomy.specialization ? taxonomy.classification : (taxonomy.doctivity_name ? taxonomy.doctivity_name : taxonomy.classification);
      }
      if (colname==='specialization') {
        retVal = (taxonomy.doctivity_name && taxonomy.specialization) ? taxonomy.doctivity_name : taxonomy.specialization;
      }
    }
    return retVal;
  }

  static getNameFromAffiliatedTaxonomy(provider) {
    let specialty = '';
    if (provider) {
      if (provider["taxonomy.doctivity_name"]) {
        specialty = provider["taxonomy.doctivity_name"];
      } else {
        if (provider["taxonomy.specialization"]) {
          specialty = provider["taxonomy.specialization"];
        } else if (provider["taxonomy.classification"]) {
          specialty = provider["taxonomy.classification"];
        }
      }
    }
    return specialty;
  }

}

module.exports = { SpecialtyUtil };
// export { ServiceLineUtil };
// export default ServiceLineUtil;
