import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  Typography,
  IconButton,
  Tooltip
} from '@mui/material';
import TableViewIcon from '@mui/icons-material/TableView';
import { GetApp } from '@mui/icons-material';

import { subDays, startOfDay, endOfDay } from 'date-fns';

import withStyles from '@mui/styles/withStyles';

import { withRouter } from 'utils';
import Chart from 'react-apexcharts';
import {
  LoadingView,
} from 'components';
import { axiosInstance, StringUtil } from 'utils';
import { CsvUtil, StoreUtil, DateUtil } from 'doctivity-shared/utils';
import { TagsUtil } from '../utils';
import { ColorUtil } from 'utils';
import { loadAnalytics } from 'store/actions/analyticsActions';
import fileDownload from 'js-file-download';
import moment from 'moment';

const styles = (theme) => ({
  filters: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  spacer: {
    flex: 1,
  },
  pdfButton: {
    flex: 1,
  },
  pdfIcon: {
    fontSize: 20,
    fontWeight: 200,
  },
  filterSpace: {
    width: 4,
  },
  rangeControl: {
    minWidth: 90,
  },
  userFilter: {
    minWidth: 270,
  },
  typeControl: {
    minWidth: 260,
  },
  hamberder: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
});


class ProviderCommentsReportChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      encounterType: 'All',
      startDate: startOfDay(subDays(new Date(), 365)),
      endDate: endOfDay(new Date()),
      query: {
        order: [['created_notes', 'DESC']],
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    if (StoreUtil.needsLoadNoCache(this.props.commentsReport)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    // anytime the client changes
    if (this.props.app.selectedClient !== prevProps.app.selectedClient 
      || this.props.encounterType !== prevProps.encounterType
      || this.props.filterUser !== prevProps.filterUser
      || this.props.dateRange.startDate !== prevProps.dateRange.startDate
      || this.props.dateRange.endDate !== prevProps.dateRange.endDate) {
      this.fetchData();
    } else if (StoreUtil.needsLoadMediumCache(this.props.commentsReport)) {
      this.fetchData();
    } else if (
      StoreUtil.hasSavedSinceUpdate(
        this.props.editComment,
        prevProps.editComment
      )
    ) {
      this.fetchData();
    }
  }

  fetchData() {
    const { query } = this.state;
    const { dispatch, clientId, encounterType } = this.props;

    let filter = {
      client_id: clientId,
      user_id: this.props.filterUser?.id,
      comment_date: {
        $gte: this.props.dateRange.startDate.toISOString(),
        $lte: this.props.dateRange.endDate.toISOString(),
      },
    }
    
    if (encounterType !== 'All') {
      filter.encounter_type_id = encounterType;
    }

    dispatch(
      loadAnalytics({
        type: 'PROVIDER_COMMENTS_REPORT',
        filter: filter,
        opts: {
          limit: 100,
          offset: 0,
          order: query.order,
        },
      })
    );
  }

  fetchCSVDownload() {
    const { query } = this.state;
    const { clientId } = this.props;
    const { startDate, endDate } = this.props.dateRange;

    axiosInstance
      .post('/Analytics', {
        type: 'PROVIDER_COMMENTS_REPORT',
        filter: {
          client_id: clientId,
          comment_date: {
            $gte: DateUtil.formatDateTimeFromDB(startDate),
            $lte: DateUtil.formatDateTimeFromDB(endDate),
          },
        },
        opts: {
          limit: query.limit,
          offset: query.offset,
          order: query.order,
        },
        format: 'csv',
        headers: {
          'Content-Type': 'text/csv',
        },
      },
      {
        params: {
          selected_client_id: clientId,
        }
      })
      .then((response) => {
        const filename = `doctivity_notes_report_${moment().format(
          'YY_MM_DD'
        )}.csv`;
        try {
          const data = this.formatCSV(response.data);
          fileDownload(data, filename);
        } catch (err) {
          console.error(`Could not format csv for ${filename}`);
          console.error(err);
          fileDownload(response.data, filename);
        }
      });
  }

  formatCSV(data) {
    let csvColumns = CsvUtil.stringToMatrix(data);
    if (csvColumns.length > 0) {
      let firstNameIndex = -1;
      let lastNameIndex = -1;
      for (let index = 0; index < csvColumns[0].length; index++) {
        if (csvColumns[0][index] === 'user.first_name') {
          firstNameIndex = index;
        } else if (csvColumns[0][index] === 'user.last_name') {
          lastNameIndex = index;
        }
      }

      if (lastNameIndex > -1) {
        for (let index = 0; index < csvColumns.length; index++) {
          if (csvColumns[index].length > 2) {
            const tempField = csvColumns[index][2];
            csvColumns[index][2] = csvColumns[index][lastNameIndex];
            csvColumns[index][lastNameIndex] = tempField;
          }
        }
      }

      if (firstNameIndex > -1) {
        for (let index = 0; index < csvColumns.length; index++) {
          if (csvColumns[index].length > 1) {
            const tempField = csvColumns[index][1];
            csvColumns[index][1] = csvColumns[index][firstNameIndex];
            csvColumns[index][firstNameIndex] = tempField;
          }
        }
      }
    }

    return CsvUtil.matrixToString(csvColumns);
  }

  render() {
    const { commentsReport, classes} = this.props;

    if (!StoreUtil.isLoaded(commentsReport)) {
      return <LoadingView />;
    }

    //let hasData = false;    
    //if (commentsReport.data.length>0) {
      //hasData = true;
    //}

    const categories = [];
    let data1 = [];
    let data2 = [];
    let data3 = [];
    let data4 = [];

    StoreUtil.getData(commentsReport).forEach((row) => {
      categories.push(row.user.last_name + ', ' + row.user.first_name);
      data1.push(row.provider_count);
      data2.push(row.contact_count);
      data3.push(row.location_count);
      data4.push(row.created_notes);

    });
    let colors = ColorUtil.getColors(9);
    colors[3] = colors[4];
    let series = [{name: 'Provider Count', group: 'one', data: data1},{name: 'Contact Count', group: 'one', data: data2},{name: 'Location Count', group: 'one',  data: data3}, {name: 'Created Activity Notes', group: 'two',  data: data4}] ;
    const namespaces = TagsUtil.getNamespacesByType('activity_notes', this.props.tagNamespaces) || [];
    let namespace = null;
    if (this.state.labelType) {
      namespace = TagsUtil.findNamespace(this.state.labelType, this.props.tagNamespaces);
    }
    if (!namespace && namespaces.length > 0) {
      namespace = namespaces[0];
    }

    const height = (categories.length * 130) + 50;
    let maxVal = Math.max(...data1, ...data2,...data3);
    const stepSize = maxVal <=20 ? 1 : 
        (maxVal <= 50 ? 5 : 
          (maxVal <= 200 ? 10 : 
            (maxVal <= 1000 ?  (maxVal/100).toFixed(0) * 10 : 
              (maxVal/1000).toFixed(0) * 100)));   


    return (
            <>
      <div className={classes.header}>
        <Typography component='h2' variant='h6' color='primary'>
          Activity Notes By User
        </Typography>

        <div className={classes.pdfButton}>
          <IconButton
            onClick={() => {
              this.fetchCSVDownload();
            } }
            size='large'
          >
            <GetApp className={classes.pdfIcon} />
          </IconButton>
        </div>
        <div className={classes.spacer} />

          <div className={classes.hamberder}>
            <IconButton
              className={classes.menuButton}
              onClick={() => this.props.showTable()}
              size='large'
            >
              <Tooltip title='Show Table'>
                <TableViewIcon />
              </Tooltip>
            </IconButton>
          </div>
      </div>

      <Chart
          type='bar'
          height={height}
          options={{
            stroke: {
              width: 1,
              colors: ['#fff']
            },
            chart: {
              id: 'ActivityNotesByLabel',
              toolbar: {
                show: false,
              },
              animations: {
                enabled: false,
              },
              stacked: true,
            },
            yaxis: {
              labels: {
                minWidth: 100,
              },
              stepSize: 1,
            },
            xaxis: {
              categories,

              stepSize: stepSize,
              labels: {
                formatter: (val) => {
                  return StringUtil.numberForChart(val);
                },
              },
            },
            tooltip: {
              enabled: true,
            },
            plotOptions: {
              bar: {
                horizontal: true,
              }
            },
            dataLabels: {
              enabled: true,
              dropShadow: {
                enabled: true,
                left: 0,
                top: 0,
                opacity: 0.4,
              },
              formatter: StringUtil.numberForChart,
              offsetY: 0,
            },
            colors: colors
          }}
          series={series} />
        </>
    );

  }

}

ProviderCommentsReportChart.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  router: PropTypes.object.isRequired,
  app: PropTypes.object,
  user: PropTypes.object,
  editComment: PropTypes.object,
  commentsReport: PropTypes.object.isRequired,
  dateRange: PropTypes.object,
  encounterType: PropTypes.object,
  tagNamespaces: PropTypes.object,
  activityNotes: PropTypes.object.isRequired,
  filterUser: PropTypes.object,
  showTable: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { app, analytics, user, providerComments } = state;

  return {
    app,
    user,
    clientId: app.selectedClient,
    editComment: StoreUtil.get(providerComments, StoreUtil.COMMON_EDIT_ITEM),
    commentsReport: StoreUtil.get(analytics, 'PROVIDER_COMMENTS_REPORT'),
    tagNamespaces: TagsUtil.getAllNamespaces(state.tagNamespaces),
    activityNotes: StoreUtil.get(state.analytics, 'PROVIDER_COMMENTS_BY_LABEL_TYPE')
  };
}

const styled = withStyles(styles)(ProviderCommentsReportChart);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ProviderCommentsReportChart };
