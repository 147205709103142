import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'utils';
import { GetApp } from '@mui/icons-material';
import Chart from 'react-apexcharts';
import { ColorUtil } from 'utils';
import { withIsMobile } from 'utils';
import {
   IconButton,
   Typography,
  Stack
} from '@mui/material';
const styles = (theme) => ({
  filters: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  header: {
    
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    width: '100%',
    textAlign: 'center'
  },
  spacer: {
    flex: 1,
  },
  pdfButton: {
    flex: 1
  },
  pdfIcon: {
    fontSize: 20,
    fontWeight: 200,
  },
  filterSpace: {
    width: 4,
  },
  rangeControl: {
    minWidth: 90,
  },
  userFilter: {
    minWidth: 270,
  },
  typeControl: {
    minWidth: 260,
  },
  hamberder: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  chartCard: {
    borderWidth: '0', 
    boxShadow: 'none', 
    backgroundColor: 'transparent'
  }


});


class ProviderCommentsLabelChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        limit: 25,
        offset: 0,
      },
      isShown: false
    };
  }

  componentDidMount() {

  }

  componentDidUpdate() {

  }

  render() {
    const { series, namespace, classes, filterUser} = this.props;
    const { showDownload } = this.state;

    let labels = this.props.categories;
    let counter = 0;
    let colors = ColorUtil.getColors(10);
    
    let newseries = [];
    let newlabels = [];

    series.map((obj) => {
      if (counter === 9) {
        newseries.push(obj);
        newlabels.push('Other');
      } else if (counter>9) {
        newseries[9] = newseries[9] + obj;
      } else {
        newseries.push(obj);
        newlabels.push(labels[counter]);
      }
      counter++;
    });

    return (
      <>
       <div className={classes.header}
        onMouseEnter={() => this.showDownload(true)}
        onMouseLeave={() => this.showDownload(false)}
       >
        <Stack direction='row' spacing={1} alignContent={'center'} textAlign={'center'}>
          <Typography fontWeight='normal' color='black'  style={{ marginLeft: '50px', width: '100%',  align: 'center', marginTop: '30px'}}>
            {namespace.name}
          </Typography>
          {showDownload && ( 
            <IconButton
                style={{ paddingTop: '20px'}}
                onClick={() => {
                  this.props.fetchCSVDownload(filterUser);
                } }
                size='large'
              >
                <GetApp className={classes.pdfIcon} />
              </IconButton>
          )}
           {!showDownload && ( 
           <div style={{width: '55px'}}></div>
          )}
          
        </Stack>

      </div>
      <Chart
        type='donut'
        height={415}
        options={{
          legend: {
            floating: false,
            fontSize: '9px',                
            horizontalAlign: 'center', 
            position: 'bottom',
            itemMargin: {
              horizontal: 5,
              vertical: 0
          },
          },
          //...(isMobile ? { legend: { horizontalAlign: 'left', position: 'bottom' } } : {}),

          labels: newlabels,
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
              // val represents the percentage value, and opts.w.config.series gives the series data
              const value = opts.w.config.series[opts.seriesIndex];
              return value; // Shows actual value instead of percentage
            }
          },
          tooltip: {
            y: {
              formatter: function (val, opts) {
                const total = opts.config.series.reduce((a, b) => a + b, 0);
                const percentage = (val / total) * 100;
                return percentage.toFixed(1) + '%'; // Shows the percentage in the tooltip
              }
            }
          },
        //  plotOptions: {
          //  pie: {
            //  donut: {
              //  labels: {
                //  show: true,
                  //total: {
                    //show: true,
                    //label: 'Total Notes',
                    //formatter: (val) => val.config.series.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                  //}
                //}
              //}
            //}
          //},
          colors: colors,
          responsive: [{
            breakpoint: 1780,
            options: {
              chart: {
                height: 390
              },
              legend: { 
                position: 'bottom',
                itemMargin: {
                  horizontal: 5,
                  vertical: 0
                },
              }
              
            },
          },
          {
            breakpoint: 1440,
            options: {
              chart: {
                height: 360
              },
              legend: { 
                position: 'bottom',
                itemMargin: {
                  horizontal: 5,
                  vertical: 0
                },
              }
              
            },
          }]
        }}
        series={newseries}
      />
    
      </>
      
    );
  }

  showDownload(showIt) {
    this.setState({
      'showDownload': showIt
    });
  }

}

ProviderCommentsLabelChart.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  router: PropTypes.object.isRequired,
  app: PropTypes.object,
  user: PropTypes.object,
  namespace: PropTypes.object,
  series: PropTypes.object,
  categories: PropTypes.object,
  isMobile: PropTypes.bool.Chart,
  fetchCSVDownload: PropTypes.func.isRequired,
  filterUser: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const { app, user } = state;

  return {
    app,
    user,
    clientId: app.selectedClient,
    //tagNamespaces: TagsUtil.getAllNamespaces(state.tagNamespaces),

  };
}

const styled = withStyles(styles)(withIsMobile(ProviderCommentsLabelChart));
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ProviderCommentsLabelChart};
