export const loadFavoriteProviders = (options) => ({
  storeId: 'favorite-providers-' + options.clientId,
  type: 'DASHBOARD_FAVORITE_PROVIDERS',
  request: {
    url: '/v2/my/providers/favorites',
    method: 'GET',
    params: {
      clientId: options.clientId,
      limit: 11,
    },
  },
});
export const loadRecentProviders = (options) => ({
  storeId: 'recent-providers-' + options.clientId,
  type: 'DASHBOARD_RECENT_PROVIDERS',
  request: {
    url: '/v2/my/providers/viewed',
    method: 'GET',
    params: {
      clientId: options.clientId,
      limit: 11,
    },
  },
});

export const loadProviderTopDx = (options) => ({
  storeId: 'analytics-provider-topdx-' + options.providerId,
  type: 'DASHBOARD_FAVORITE_PROVIDER_ANALYTICS_DX',
  request: {
    url: 'v2/analytics/providers/topdx',
    method: 'GET',
    params: {
      provider_id: options.providerId,
      limit: 3,
    },
  },
});

export const loadProviderTopPx = (options) => ({
  storeId: 'analytics-provider-toppx-' + options.providerId,
  type: 'DASHBOARD_FAVORITE_PROVIDER_ANALYTICS_PX',
  request: {
    url: 'v2/analytics/providers/toppx',
    method: 'GET',
    params: {
      provider_id: options.providerId,
      limit: 3,
    },
  },
});

export const loadProviderTopServiceLines = (options) => ({
  storeId: 'analytics-provider-topservicelines-' + options.providerId,
  type: 'DASHBOARD_FAVORITE_PROVIDER_ANALYTICS_TOP_SERVICELINES',
  request: {
    url: 'v2/analytics/providers/topservicelines',
    method: 'GET',
    params: {
      provider_id: options.providerId,
      limit: 3,
    },
  },
});

export const loadFavoriteLocations = (options) => ({
  storeId: 'favorite-locations-' + options.clientId,
  type: 'DASHBOARD_FAVORITE_LOCATIONS',
  request: {
    url: '/v2/my/locations/favorites',
    method: 'GET',
    params: {
      clientId: options.clientId,
      limit: 11,
    },
  },
});
export const loadRecentLocations = (options) => ({
  storeId: 'recent-locations-' + options.clientId,
  type: 'DASHBOARD_RECENT_LOCATIONS',
  request: {
    url: '/v2/my/locations/viewed',
    method: 'GET',
    params: {
      clientId: options.clientId,
      limit: 11,
    },
  },
});

export const loadFavoriteContacts = (options) => ({
  storeId: 'favorite-contacts-' + options.clientId,
  type: 'DASHBOARD_FAVORITE_CONTACTS',
  request: {
    url: '/v2/my/contacts/favorites',
    method: 'GET',
    params: {
      clientId: options.clientId,
      limit: 11,
    },
  },
});
export const loadRecentContacts = (options) => ({
  storeId: 'recent-contacts-' + options.clientId,
  type: 'DASHBOARD_RECENT_CONTACTS',
  request: {
    url: '/v2/my/contacts/viewed',
    method: 'GET',
    params: {
      clientId: options.clientId,
      limit: 11,
    },
  },
});

export const loadLocationActivityNotes = (options) => ({
  storeId:
    'analytics-location-activitynotes-' +
    options.clientId +
    '-' +
    options.locationId,
  type: 'DASHBOARD_FAVORITE_LOCATION_ANALYTICS_ACTIVITY_NOTES',
  request: {
    url: 'v2/analytics/locations/totalactivitynotes',
    method: 'GET',
    params: {
      location_id: options.locationId,
      client_id: options.clientId,
    },
  },
});

export const loadLocationConnections = (options) => ({
  storeId:
    'analytics-location-connections-' +
    options.clientId +
    '-' +
    options.locationId,
  type: 'DASHBOARD_FAVORITE_LOCATION_ANALYTICS_CONNECTIONS',
  request: {
    url: 'v2/analytics/locations/connections',
    method: 'GET',
    params: {
      location_id: options.locationId,
      client_id: options.clientId,
    },
  },
});

export const loadContactActivityNotes = (options) => ({
  storeId: `analytics-contact-activitynotes-${options.clientId}-${options.contactId}`,
  type: 'DASHBOARD_FAVORITE_CONTACT_ANALYTICS_ACTIVITYNOTES',
  request: {
    url: 'v2/analytics/contacts/totalactivitynotes',
    method: 'GET',
    params: {
      contact_id: options.contactId,
      client_id: options.clientId,
    },
  },
});

export const loadUnresolvedActivityNotes = (clientId, userId) => ({
  storeId: `analytics-unresolved-activitynotes-${clientId}`,
  type: 'DASHBOARD_UNRESOLVED_ACTIVITY_NOTES_TABLE',
  request: {
    url: '/ProviderComments',
    method: 'GET',
    params: {
      opts: {
        distinct: true,
        where: {
          needs_followup: true,
          client_id: clientId,
          $or: [{ is_resolved: false }, { is_resolved: null }],
        },
        include: [
          {
            association: 'user',
            attributes: ['id', 'first_name', 'last_name'],
            where: {
              id: userId,
            },
          },
          {
            association: 'providers',
            attributes: ['id', 'first_name', 'last_name', 'middle_name'],
          },
          {
            association: 'contacts',
            attributes: ['id', 'first_name', 'last_name'],
          },
          {
            association: 'locations',
            attributes: ['name', 'address1', 'city', 'state'],
          }
        ],
        order: [['due_date', 'DESC']],
        limit: 10,
        offset: 0,
      },
    },
  },
});

export const loadRecentActivityNotes = (clientId, userId) => ({
  storeId: `analytics-recent-activitynotes-${clientId}`,
  type: 'DASHBOARD_RECENT_ACTIVITY_NOTES_TABLE',
  request: {
    url: '/ProviderComments',
    method: 'GET',
    params: {
      opts: {
        distinct: true,
        where: {
          client_id: clientId,
        },
        include: [
          {
            association: 'user',
            attributes: ['id', 'first_name', 'last_name'],
            where: {
              id: userId,
            },
          },
          {
            association: 'providers',
            attributes: ['id', 'first_name', 'last_name', 'middle_name'],
          },
          {
            association: 'contacts',
            attributes: ['id', 'first_name', 'last_name'],
          },
          {
            association: 'locations',
            attributes: ['name', 'address1', 'city', 'state'],
          }
        ],
        order: [['comment_date','desc'],['created_at', 'desc']],
        limit: 10,
        offset: 0,
      },
    },
  },
});

export const loadRecentSearches = (options) => ({
  storeId: `analytics-recent-searches-${options.clientId}`,
  type: 'DASHBOARD_RECENT_SEARCHES',
  request: {
    url: 'v2/my/searches/recent',
    method: 'GET',
    params: {
      clientId: options.clientId,
    },
  },
});


