import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  Typography
} from '@mui/material';

import { subDays, startOfDay, endOfDay } from 'date-fns';

import withStyles from '@mui/styles/withStyles';

import { withRouter } from 'utils';
import Chart from 'react-apexcharts';
import {
  LoadingView,
} from 'components';
import { StoreUtil, SpecialtyUtil } from 'doctivity-shared/utils';
//import { TagsUtil } from '../utils';
import { ColorUtil } from 'utils';
import { loadAnalytics } from 'store/actions/analyticsActions';
import { withIsMobile } from 'utils';

const styles = (theme) => ({
  filters: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  spacer: {
    flex: 1,
  },
  pdfButton: {
    flex: 1,
  },
  pdfIcon: {
    fontSize: 20,
    fontWeight: 200,
  },
  filterSpace: {
    width: 4,
  },
  rangeControl: {
    minWidth: 90,
  },
  userFilter: {
    minWidth: 270,
  },
  typeControl: {
    minWidth: 260,
  },
  hamberder: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  chartCard: {
    borderWidth: '0', 
    boxShadow: 'none', 
    backgroundColor: 'transparent'
  }


});


class ProviderCommentsSpecialtiesChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      encounterType: 'All',
      startDate: startOfDay(subDays(new Date(), 365)),
      endDate: endOfDay(new Date()),
      query: {
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    if (StoreUtil.needsLoadNoCache(this.props.specialtyReport)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    // anytime the client changes
    if (this.props.app.selectedClient !== prevProps.app.selectedClient 
      || this.props.encounterType !== prevProps.encounterType
      || this.props.filterUser !== prevProps.filterUser
      || this.props.dateRange.startDate !== prevProps.dateRange.startDate
      || this.props.dateRange.endDate !== prevProps.dateRange.endDate) {
      this.fetchData();
    } else if (StoreUtil.needsLoadMediumCache(this.props.specialtyReport)) {
      this.fetchData();
    }
  }

  fetchData() {
    //const { query } = this.state;
    const { dispatch, clientId, encounterType } = this.props;

    let filter = {
      client_id: clientId,
      encounter_type_id: encounterType,
      user_id: this.props.filterUser?.id,
      comment_date: {

        $gte: this.props.dateRange.startDate.toISOString(),
        $lte: this.props.dateRange.endDate.toISOString(),
      },
    }
    
    if (encounterType !== 'All') {
      filter.encounter_type_id = encounterType;
    }

    dispatch(
      loadAnalytics({
        type: 'PROVIDERS_SPECIALTY_COUNT',
        filter: filter,
        opts: {
          limit: 100,
          offset: 0,
          //order: query.order,
        },
      })
    );
  }

  

  render() {
    const { classes, } = this.props;
    const { specialtyReport} = this.props;

    if (!StoreUtil.isLoaded(specialtyReport)) {
      return <LoadingView />;
    }

    let series = [];
    let labels = [];
    let data = specialtyReport.data;

    let emptyChart = false;
    if (data.length==0) {
      emptyChart = true;
    } else {
    
      data = data.sort((a, b) => b.totalproviders - a.totalproviders);

      let counter = 0

      data.map((obj) => {
        if (counter === 9) {
          series.push(obj.totalproviders);
          labels.push('Other');
        } else if (counter>9) {
          series[9] = series[9] + obj.totalproviders;
        } else {
          series.push(obj.totalproviders);
          let lbl = SpecialtyUtil.getNameFromTaxonomy(obj);
          if (lbl=='') {
            lbl = 'None';
          }
          labels.push(lbl);
        }
        counter++;
      });

    }

    let colors = ColorUtil.getColors(10);
    
    return (
      <>
      <Chart
        type='donut'
        height={415}
        //height={isMobile ? 400 : 310}
        options={{
          
          legend: {
            floating: false,
            fontSize: '9px',                
            horizontalAlign: 'center', 
            position: 'bottom',
            itemMargin: {
              horizontal: 5,
              vertical: 0
          },
          },
          //...(isMobile ? { legend: { horizontalAlign: 'left', position: 'bottom' } } : {}),

          labels,
          colors: colors,
          responsive: [{
            breakpoint: 1780,
            options: {
              chart: {
                height: 390
              },
              legend: { 
                position: 'bottom',
                itemMargin: {
                  horizontal: 5,
                  vertical: 0
                },
              }
              
            },
          },
          {
            breakpoint: 1440,
            options: {
              chart: {
                height: 360
              },
              legend: { 
                position: 'bottom',
                itemMargin: {
                  horizontal: 5,
                  vertical: 0
                },
              }
              
            },
          }]
        }}
        series={series}
      />
      
      { emptyChart && (
      <Typography className={classes.nothingFound}>
        No data available.
      </Typography>
      )}
      </>
    );
  }

}

ProviderCommentsSpecialtiesChart.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  router: PropTypes.object.isRequired,
  app: PropTypes.object,
  user: PropTypes.object,
  specialtyReport: PropTypes.object.isRequired,
  dateRange: PropTypes.object,
  encounterType: PropTypes.object,
  //tagNamespaces: PropTypes.object,
  filterUser: PropTypes.object,
  isMobile: PropTypes.bool
};

function mapStateToProps(state) {
  const { app, analytics, user } = state;

  return {
    app,
    user,
    clientId: app.selectedClient,
    specialtyReport: StoreUtil.get(analytics, 'PROVIDERS_SPECIALTY_COUNT'),
    //tagNamespaces: TagsUtil.getAllNamespaces(state.tagNamespaces),

  };
}

const styled = withStyles(styles)(withIsMobile(ProviderCommentsSpecialtiesChart));
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ProviderCommentsSpecialtiesChart};
