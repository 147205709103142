import { ReducerUtil } from 'doctivity-shared/utils';
import moment from 'moment';
import {
  DateUtil,
  EncounterTypeUtil,
} from 'doctivity-shared/utils';
import { ProviderNotesUtil } from 'utils';
//import { success } from 'redux-saga-requests';

export const analytics = (state = {}, action = null) => {
  if (action.type.startsWith('ANALYTICS_')) {
    let type = action.type;
    let storeId = action.store_id;
    if (action.meta && action.meta.requestAction) {
      type = action.meta.requestAction.type;
      storeId = action.meta.requestAction.store_id;
    }
    const field = storeId || type.substring(10);
    let result;
    if (Array.isArray(action.data)) {
      result = ReducerUtil.reduceListGet(state, action, type, field);
    } else if (Number.isInteger(action.data)) {
      result = ReducerUtil.reduceQuery(state, action, type, field);
    } else {
      result = ReducerUtil.reduceTableGet(state, action, type, field);
    }
    if (result.actionConsumed) {


      if (action.type === 'ANALYTICS_PROVIDER_COMMENTS_PER_DAY') {
        result.state.seriesActivityNotes = [];
        result.state.activityNotesPerDay = result.state.PROVIDER_COMMENTS_PER_DAY;
      } else if (action.type === 'ANALYTICS_PROVIDER_COMMENTS_PER_DAY_SUCCESS') {

        let dateUnits = action.meta.requestAction.request.data.dateUnits;
        let startDate = action.meta.requestAction.request.data.filter.comment_date.$gte;
        let endDate = action.meta.requestAction.request.data.filter.comment_date.$lte;
        
        // convert the dates back to local
        startDate = new Date(`${startDate}`).toLocaleString();
        endDate = new Date(`${endDate}`).toLocaleString();

        result.state.activityNotesPerDay = result.state.PROVIDER_COMMENTS_PER_DAY;

        // Category year is needed to calculate series, so we will reformat after
        let categories = DateUtil.listUnitsBetween(startDate, endDate, dateUnits);
        const startDay = moment(startDate);

        // create empty series
        const seriesByType = {}; 
        EncounterTypeUtil.getAllWithUnspecified().forEach((type) => {
          seriesByType[type.id || 0] = {
            name: type.name,
            data: Array(categories.length).fill(0),
          };
        });
       
        let totalActivityNotes = 0;
        result.state.activityNotesPerDay?.data.forEach((notes) => {
          let index;
          if (dateUnits === 'days') {
            const day = moment(`${notes.encounter_date}${notes.encounter_date_day < 10 ? '0' : ''}${notes.encounter_date_day}`, 'YYYYMMDD');
             index = day.diff(startDay, 'days');
    
          } else { // months
            const month = moment(notes.encounter_date, 'YYYYMM');
            index = month.diff(startDay, 'months');
          }
          seriesByType[notes.encounter_type_id || 0].data[index] += notes.activity_notes;
          totalActivityNotes += notes.activity_notes;

        })
        result.state.totalActivityNotes = totalActivityNotes; 

        let series = [];
        

        series = Object.keys(seriesByType).map((typeId) => {
          return seriesByType[typeId];
        });
        result.state.seriesActivityNotes = series;

        categories = categories?.map((date) =>
          moment(date).format(dateUnits === 'days' ? 'MMM DD' : 'MMM YY')
        );
        result.state.categories = categories;

      } else if ( action.type ==='ANALYTICS_PROVIDER_COMMENTS_TAGGED_PER_DAY') {
        result.state.seriesActivityNotesTagged = [];
        result.state.activityNotesTaggedPerDay = result.state.PROVIDER_COMMENTS_TAGGED_PER_DAY;
      
      } else if ( action.type === 'ANALYTICS_PROVIDER_COMMENTS_TAGGED_PER_DAY_SUCCESS') {
        let dateUnits = action.meta.requestAction.request.data.dateUnits;
        let startDate = action.meta.requestAction.request.data.filter.comment_date.$gte;
        let endDate = action.meta.requestAction.request.data.filter.comment_date.$lte;

        // convert the dates back to local
        startDate = new Date(`${startDate}`).toLocaleString();
        endDate = new Date(`${endDate}`).toLocaleString();


        let types = action.meta.requestAction.request.data.types;

        result.state.activityNotesTaggedPerDay = result.state.PROVIDER_COMMENTS_TAGGED_PER_DAY;

        // Category year is needed to calculate series, so we will reformat after
        let categories = DateUtil.listUnitsBetween(startDate, endDate, dateUnits);
        const series = types.map((type) => {
          const name = ProviderNotesUtil.formatCountTypeForDisplay(type);
    
          return {
            name,
            data: categories.map((category) => {
              const categoryDate = moment(category);
              const formattedCategory = parseInt(categoryDate.format('YYYYMM'));
              const formattedDay = categoryDate.date();
              const taggedPerDayByTypeOnDate =
                result.state.activityNotesTaggedPerDay.data?.filter((notes) => {
                  if (dateUnits === 'days') {
                    return (
                      notes.encounter_date === formattedCategory &&
                      notes.encounter_date_day === formattedDay
                    );
                  }
    
                  return notes.encounter_date === formattedCategory;
                });
    
              return (
                taggedPerDayByTypeOnDate
                  ?.map((notes) => notes[type])
                  ?.reduce((notes, sum) => sum + notes, 0) || 0
              );
            }),
          };
        });
    
        result.state.seriesActivityNotesTagged = series;

        categories = categories?.map((date) =>
          moment(date).format(dateUnits === 'days' ? 'MMM DD' : 'MMM YY')
        );
        result.state.categoriesTagged = categories;
    
      }
      return result.state;
    }

  }

  
  return state;
};
