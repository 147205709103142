import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import {

  FormControl,
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers';

import withStyles from '@mui/styles/withStyles';

import { withRouter } from 'utils';
import {
  StoreUtil,
  DateUtil,
  EncounterTypeUtil,
} from 'doctivity-shared/utils';

import { LoadingView } from 'components';
import { listActiveUsers } from 'store/actions/usersActions';
import moment from 'moment';

const styles = (theme) => ({
  filters: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  spacer: {
    flex: 1,
  },
  pdfButton: {
    flex: 1,
  },
  pdfIcon: {
    fontSize: 20,
    fontWeight: 200,
  },
  filterSpace: {
    width: 4,
  },
  rangeControl: {
    minWidth: 90,
  },
  userFilter: {
    minWidth: 270,
  },
  typeControl: {
    minWidth: 260,
  },
  totalCount: {
    textAlign: 'center',
    minWidth: 75
  },
  totalCountText: {
    fontSize: 24,
    fontWeight: 500,
  },
  totalCountDescriptor: {
    fontWeight: 500,
    fontSize: 14
  },

});

const minimumStartDate = new Date(2018, 0, 1);

class ProviderCommentsOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterUser: null,
      encounterType: 'All',
      filterUser: 'All Users'

    };
  }
 
  componentDidMount() {
    if (
      StoreUtil.needsLoadLongCache(this.props.users) ||
      this.props.usersClientId !== this.props.app.selectedClient
    ) {
      this.fetchUsers();
    }
  }

  getDateUnits() {
    const { startDate, endDate } = this.props.dateRange;

    return moment(endDate).diff(moment(startDate), 'days', true) > 31
      ? 'months'
      : 'days';
  }

  fetchUsers() {
    const { dispatch, app } = this.props;

    dispatch(listActiveUsers(app.selectedClient));
  }

  render() {
    const { classes, users, encounterType, filterUser } = this.props;

    if  (!StoreUtil.isLoaded(users)) {
      return <LoadingView />;
    }
   
    const options = [
      'All Users',
      ...StoreUtil.getData(users),
    ];

    return (
      <div>
            <div className={classes.filters}>
              <FormControl className={classes.userFilter}>
                <Autocomplete
                  options={options}
                  getOptionLabel={(u) =>
                    u !== 'All Users' ? `${u.last_name}, ${u.first_name}` : u}
                  autoComplete
                  disableClearable
                  defaultValue={'All Users'}
                  value={filterUser}
                  onChange={this.onChangeUser}
                  renderInput={(params) => (
                    <TextField {...params} label='User' />
                  )}
                />
              </FormControl>

              <div className={classes.filterSpace} />
              <FormControl 
                className={classes.typeControl}>
                <InputLabel>Encounter Type</InputLabel>
                <Select
                  value={encounterType}
                  onChange={this.onChangeEncounterType}
                  label='Encounter Type'
                >
                  <MenuItem value='All' key='all-encounters'>All</MenuItem>
                  {EncounterTypeUtil.getAll().map((t) => (
                    <MenuItem value={t.id} key={t.id}>
                      {t.name}
                    </MenuItem>
                  ))}
                  <MenuItem value={0} key='unspecified-encounters'>Unspecified</MenuItem>
                </Select>
              </FormControl>

              <div className={classes.filterSpace} />
              <FormControl className={classes.rangeControl}>
                <DatePicker
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // inputProps={{
                      //   ...params.inputProps,
                      //   readOnly: true,
                      // }}
                    />
                  )}
                  label='From'
                  name='from'
                  value={this.props.dateRange.startDate}
                  minDate={minimumStartDate}
                  maxDate={this.props.dateRange.endDate}
                  onChange={this.onChangeStartDate}
                  format={DateUtil.LDML_DISPLAY_FORMAT}
                />
              </FormControl>
              <div className={classes.filterSpace} />
              <FormControl className={classes.rangeControl}>
                <DatePicker
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                    />
                  )}
                  label='To'
                  value={this.props.dateRange.endDate}
                  minDate={this.props.dateRange.startDate}
                  maxDate={new Date()}
                  onChange={this.onChangeEndDate}
                  name='to'
                  format={DateUtil.LDML_DISPLAY_FORMAT}
                />
              </FormControl>
            </div>

   
      </div>
    );
  }

  onChangeUser = (event, newUser) => {
    this.props.onChangeUser(newUser);
  };

  onChangeStartDate = (newDate) => {
    if (moment(newDate).isAfter(moment(minimumStartDate))) {
      this.props.onChangeStartDate(newDate);
    }
  };

  onChangeEndDate = (newDate) => {
    this.props.onChangeEndDate(newDate);
  };

  onChangeEncounterType = (event) => {
    this.props.onChangeEncounterType(event.target.value)
  }
}

ProviderCommentsOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  router: PropTypes.object.isRequired,
  app: PropTypes.object,
  user: PropTypes.object,
  users: PropTypes.object,
  usersClientId: PropTypes.number,
  dateRange: PropTypes.object,
  onChangeStartDate: PropTypes.func,
  onChangeEndDate: PropTypes.func,
  onChangeEncounterType: PropTypes.func,
  encounterType: PropTypes.number,
  filterUser: PropTypes.object,
  onChangeUser: PropTypes.func, 
  
 
};

function mapStateToProps(state) {
  const { app, user, users } = state;

    return {
      app,
      user,
      clientId: app.selectedClient,
      users: StoreUtil.get(users, StoreUtil.COMMON_LIST),
      usersClientId: users.listClientId,
    }

}

const styled = withStyles(styles)(ProviderCommentsOptions);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ProviderCommentsOptions };
