import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  Typography,
  Card,
  CardContent,
  Grid2
} from '@mui/material';

import { subDays, startOfDay, endOfDay } from 'date-fns';

import withStyles from '@mui/styles/withStyles';

import { withRouter } from 'utils';
import { axiosInstance } from 'utils';
import {
  CsvUtil,
  StoreUtil
} from 'doctivity-shared/utils';

import { LoadingView, ProviderCommentsLabelChart } from 'components';
import { loadAnalytics } from 'store/actions/analyticsActions';
import { listTagNamespacesByClient } from 'store/actions/tagNamespacesActions';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { TagsUtil } from '../utils';

const styles = (theme) => ({
  filters: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',    
  },
  header2: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  spacer: {
    flex: 1,
  },
  pdfButton: {
    flex: 1,
  },
  pdfIcon: {
    fontSize: 20,
    fontWeight: 200,
  },
  filterSpace: {
    width: 4,
  },
  rangeControl: {
    minWidth: 90,
  },
  userFilter: {
    minWidth: 270,
  },
  typeControl: {
    minWidth: 260,
  },
});

class ProviderCommentsByLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      encounterType: 'All',
      startDate: startOfDay(subDays(new Date(), 365)),
      endDate: endOfDay(new Date()),
      filterUser: null,
      query: {
        limit: 25,
        offset: 0,
      },
      labelType: 0,
    };
  }

  componentDidMount() {
    if (StoreUtil.needsLoadNoCache(this.props.activityNotesAll)) {
      this.fetchData();
    }

    //if (StoreUtil.needsLoadLongCache(this.props.users) || this.props.usersClientId !== this.props.app.selectedClient) {
//      this.fetchUsers();
  //  }

    if (StoreUtil.needsLoadLongCache(this.props.tagNamespaces) || this.props.tagNamespaces.clientId !== this.props.clientId) {
      return this.fetchTags();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // anytime the client changes
    if (this.props.app.selectedClient !== prevProps.app.selectedClient
      || this.state.labelType !== prevState.labelType
      || this.props.filterUser !== prevProps.filterUser
      || this.props.encounterType !== prevProps.encounterType
      || this.props.dateRange.startDate !== prevProps.dateRange.startDate
      || this.props.dateRange.endDate !== prevProps.dateRange.endDate) {
      this.fetchData();

    } else if (StoreUtil.needsLoadMediumCache(this.props.activityNotesAll)) {
      this.fetchData();

    } else if (
      StoreUtil.hasSavedSinceUpdate(
        this.props.editComment,
        prevProps.editComment
      )
    ) {
      this.fetchData();
    }

    if (StoreUtil.needsLoadLongCache(this.props.tagNamespaces) || this.props.tagNamespaces.clientId !== this.props.clientId) {
      return this.fetchTags();
    } else if (!this.state.labelType) {
      const namespaces = TagsUtil.getNamespacesByType('activity_notes', this.props.tagNamespaces) || [];
      if (namespaces.length > 0) {
        // set default first label type
        this.setState({ labelType: namespaces[0].id });
      }
    }
  }

  getFilter(byLabelType) {

    return {
      client_id: this.props.clientId,
      user_id: this.props.filterUser?.id,
      encounter_type:  this.props.encounterType != 'All' ? this.props.encounterType : null,
      label_type: byLabelType ? this.state.labelType : null,
      comment_date: {
        $gte: this.props.dateRange.startDate.toISOString(),
        $lte: this.props.dateRange.endDate.toISOString(),        
      },
    };
  }

  fetchData() {
    //if (this.state.labelType) { // don't fetch until we have a label type
      //this.props.dispatch(
        //loadAnalytics({
          //type: 'PROVIDER_COMMENTS_BY_LABEL_TYPE',
          //filter: this.getFilter(true),
        //})
      //);
    //}

    // get all label types for donut charts
    this.props.dispatch(
      loadAnalytics({
        type: 'PROVIDER_COMMENTS_ALL_LABEL_TYPES',
        filter: this.getFilter(false),
      })
    );
  }


  fetchTags() {
    this.props.dispatch(
      listTagNamespacesByClient(
        this.props.clientId,
      ),
    );
  }

  newFetchCSVDownload(filterUser) {

    console.log(this.series)
    console.log(this.categories);

    let data = [];

    data.push(['name','activity_note_count']);

    this.series.map((obj, key) => {
      data.push(['"' + this.categories[key] + '"', obj]);
    })

    data = CsvUtil.matrixToString(data);

    const filename =
          filterUser && filterUser !== 'All Users'
            ? `${filterUser.first_name}_${
                filterUser.last_name
              }_doctivity_notes_by_label_${moment().format('YY_MM_DD')}.csv`
            : `doctivity_notes_by_label_${moment().format('YY_MM_DD')}.csv`;
        try {
          fileDownload(data, filename);
        } catch (err) {
          console.error(`Could not format csv for ${filename}`);
          console.error(err);
          fileDownload(data, filename);
        }
  }

  fetchCSVDownload() {
    const { filterUser } = this.state;

    axiosInstance
      .post('/Analytics', {
        type: 'PROVIDER_COMMENTS_BY_LABEL_TYPE',
        filter: this.getFilter(true),
        format: 'csv',
        headers: {
          'Content-Type': 'text/csv',
        },
      },
      {
        params: {
          selected_client_id: this.props.clientId,
        }
      })
      .then((response) => {
        const filename =
          filterUser && filterUser !== 'All Users'
            ? `${filterUser.first_name}_${
                filterUser.last_name
              }_doctivity_notes_by_label_${moment().format('YY_MM_DD')}.csv`
            : `doctivity_notes_by_label_${moment().format('YY_MM_DD')}.csv`;
        try {
          const data = this.formatCSV(response.data);
          fileDownload(data, filename);
        } catch (err) {
          console.error(`Could not format csv for ${filename}`);
          console.error(err);
          fileDownload(response.data, filename);
        }
      });
  }

  formatCSV(data) {
    let csvColumns = CsvUtil.stringToMatrix(data);
    return CsvUtil.matrixToString(csvColumns);
  }

  render() {
    const { classes, activityNotesAll,  tagNamespaces } = this.props;
    
    //if (!StoreUtil.isLoaded(activityNotes) || !StoreUtil.isLoaded()) {
    if (!StoreUtil.isLoaded(activityNotesAll) || !StoreUtil.isLoaded(tagNamespaces)) {
      return <LoadingView />;
    }

    const sdatetimedisplay = moment(this.props.dateRange.startDate).format( 'M/D/YY' );
    const edatetimedisplay = moment(this.props.dateRange.endDate).format( 'M/D/YY ' );

    const namespaces = TagsUtil.getNamespacesByType('activity_notes', tagNamespaces) || [];
    let namespace = null;
    if (this.state.labelType) {
      namespace = TagsUtil.findNamespace(this.state.labelType, tagNamespaces);
    }
    if (!namespace && namespaces.length > 0) {
      namespace = namespaces[0];
    }

    let activityNotes = [];
    if (namespace) {
      activityNotes = activityNotesAll.data.filter((note) => note.namespace_id==namespace.id)
    }

    let categories = [];
    let data = [];
    activityNotes.forEach((row) => {
      categories.push(row.name);
      data.push(row.activity_note_count);
    });

    let allData = [];

    namespaces?.map((t) => {
      let tcategories = [];
      let tdata = [];

      activityNotes = activityNotesAll.data.filter((note) => note.namespace_id === t.id);

      activityNotes.forEach((row) => {
        tcategories.push(row.name);
        tdata.push(row.activity_note_count);
      });

      allData.push([tcategories, tdata])
    })    

    return (
      <div>
        <div>
        <Card>
          <CardContent>
            <div className={classes.header}>
              <Typography component='h2' variant='h6' color='primary'>
                Activity Notes By Label
              </Typography>
            </div>
            <div className={classes.header2}>
                <Typography fontWeight='300' color='black'>
                  {sdatetimedisplay} to {edatetimedisplay}
                </Typography>
  
            </div>
            <Grid2 container marginTop='50px'  spacing={1} columns={{ xs: 1, md: 1, lg: 12, xl: 12 }}>
              {namespaces?.map((t, index) => this.displayGrid(t, allData[index][0], allData[index][1]))}
            </Grid2>
          </CardContent>
        </Card>
        </div>

      </div>
    );
  }

  displayGrid(t, tcategories, tseries) {
    if (tcategories.length > 0 ) {

      return (

        <Grid2 marginBottom='50px' size={{ xs: 1, md: 1, lg: 4, xl: 4 }} justifyContent='center' alignItems='center' key={t}> 
          <ProviderCommentsLabelChart
            namespace={t}
            series={tseries}
            categories={tcategories}
            fetchCSVDownload={this.newFetchCSVDownload}
            filterUser={this.state.filterUser}
            />
        </Grid2>  
      )     
    }
  }

  onChangeUser = (event, newUser) => {
    this.setState(
      { filterUser: newUser !== 'All Users' ? newUser : null },
      this.fetchData
    );
  };

  onChangeLabelType = (event) => {
    this.setState({
      labelType: event.target.value
    });
  }
}

ProviderCommentsByLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  router: PropTypes.object.isRequired,
  app: PropTypes.object,
  user: PropTypes.object,
  users: PropTypes.object,
  usersClientId: PropTypes.number,
  editComment: PropTypes.object,
  activityNotesAll: PropTypes.object.isRequired,
  tagNamespaces: PropTypes.object,
  dateRange: PropTypes.object,
  filterUser: PropTypes.object,
  encounterType: PropTypes.object
};

function mapStateToProps(state) {
  return {
    app: state.app,
    user: state.user,
    clientId: state.app.selectedClient,
    users: StoreUtil.get(state.users, StoreUtil.COMMON_LIST),
    usersClientId: state.users.listClientId,
    editComment: StoreUtil.get(state.providerComments, StoreUtil.COMMON_EDIT_ITEM),
    activityNotesAll: StoreUtil.get(state.analytics, 'PROVIDER_COMMENTS_ALL_LABEL_TYPES'),
    tagNamespaces: TagsUtil.getAllNamespaces(state.tagNamespaces),
  };
}

const styled = withStyles(styles)(ProviderCommentsByLabel);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ProviderCommentsByLabel };
