import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  Typography
} from '@mui/material';

import { subDays, startOfDay, endOfDay } from 'date-fns';

import withStyles from '@mui/styles/withStyles';

import { withRouter } from 'utils';
import Chart from 'react-apexcharts';
import {
  LoadingView,
} from 'components';
import { StoreUtil } from 'doctivity-shared/utils';
//import { TagsUtil } from '../utils';
//import { ColorUtil } from 'utils';
import { loadAnalytics } from 'store/actions/analyticsActions';
import { withIsMobile } from 'utils';

const styles = (theme) => ({
  filters: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  spacer: {
    flex: 1,
  },
  pdfButton: {
    flex: 1,
  },
  pdfIcon: {
    fontSize: 20,
    fontWeight: 200,
  },
  filterSpace: {
    width: 4,
  },
  rangeControl: {
    minWidth: 90,
  },
  userFilter: {
    minWidth: 270,
  },
  typeControl: {
    minWidth: 260,
  },
  hamberder: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  chartCard: {
    borderWidth: '0', 
    backgroundColor: 'transparent',
    boxShadow: 'none'
  }
});


class ProviderCommentsAffiliationChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      encounterType: 'All',
      startDate: startOfDay(subDays(new Date(), 365)),
      endDate: endOfDay(new Date()),
      query: {
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    if (StoreUtil.needsLoadNoCache(this.props.affiliationReport)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.app.selectedClient !== prevProps.app.selectedClient 
      || this.props.encounterType !== prevProps.encounterType
      || this.props.filterUser !== prevProps.filterUser
      || this.props.dateRange.startDate !== prevProps.dateRange.startDate
      || this.props.dateRange.endDate !== prevProps.dateRange.endDate) {
      this.fetchData();
    } else if (StoreUtil.needsLoadMediumCache(this.props.affiliationReport)) {
      this.fetchData();
    }
  }

  fetchData() {
    const { dispatch, clientId, encounterType } = this.props;

    let filter = {
      client_id: clientId,
      encounter_type_id: encounterType,
      user_id: this.props.filterUser?.id,
      comment_date: {
        $gte: this.props.dateRange.startDate.toISOString(),
        $lte: this.props.dateRange.endDate.toISOString(),
      },
    }
    
    if (encounterType !== 'All') {
      filter.encounter_type_id = encounterType;
    }

    dispatch(
      loadAnalytics({
        type: 'PROVIDERS_AFFILIATION_COUNT',
        filter: filter,
        opts: {
          limit: 100,
          offset: 0,
          //order: query.order,
        },
      })
    );
  }

  

  render() {
    const { affiliationReport, classes} = this.props;

    if (!StoreUtil.isLoaded(affiliationReport)) {
      return <LoadingView />;
    }

    let data = affiliationReport.data;
    let total = data[0] + data[1];
    let series = [((data[1]/total)*100).toFixed(1)]
    let emptyChart = false;

    if (total === 0) {
      emptyChart = true;
    }

    return (
      <>
      <Chart
              type='radialBar'
              height={505}
              //height={isMobile ? 400 : 310}
              options={{
                colors: ['#FF6178'],
                tooltip: {
                  theme:'dark',
                  enabled: true,
                  marker: {
                    show: false,
                  },
                  y: {
                    formatter: function() {
                      return ''
                    },
                    title: {
                      formatter: function(val, nbr) {
                        let num = nbr.series[0]*1;
                        let num2 = 100 - num;

                        let newval = `: ${num.toFixed(1)}%<br/>Affiliated: ${num2.toFixed(1)}%`;
                        
                        return val + newval;
                      }
                    }
                  }
                  
                },
                plotOptions: {
                  radialBar: {
                    track: {
                      background: '#2083F4'
                    },
                    offsetY: -55,
                    startAngle: -135,
                    endAngle: 135,
          
                    dataLabels: {
                      name: {
                        fontSize: '16px',
                        color: 'black',//colors[0],
                        offsetY: 120,
                        fontWeight: 'normal',

                      },
                      value: {
                        offsetY: 76,
                        fontSize: '22px',
                        //color: undefined,
                        formatter: function(val) {
                          return val + '%';
                        }
                      }
                    }
                  },
                  
                },
                responsive: [
                  {
                    breakpoint: 1440,
                    options: {
                      chart: {
                        height: 425
                      },
                    }
                      
                      
                  },
                  {
                    breakpoint: 1780,
                    options: {
                      chart: {
                        height: 485
                      },
                    }
                      
                      
                  },

                ],


                stroke: {
                  dashArray: 0
                },
                labels: ['Unaffiliated']                
              }}
              series={series}
            />
            
      { emptyChart && (
      <Typography className={classes.nothingFound}>
        No data available.
      </Typography>
      )}


      </>
    );
  }

}

ProviderCommentsAffiliationChart.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  router: PropTypes.object.isRequired,
  app: PropTypes.object,
  user: PropTypes.object,
  affiliationReport: PropTypes.object.isRequired,
  dateRange: PropTypes.object,
  encounterType: PropTypes.object,
  //tagNamespaces: PropTypes.object,
  filterUser: PropTypes.object,
  isMobile: PropTypes.bool
};

function mapStateToProps(state) {
  const { app, analytics, user } = state;

  return {
    app,
    user,
    clientId: app.selectedClient,
    affiliationReport: StoreUtil.get(analytics, 'PROVIDERS_AFFILIATION_COUNT'),
    //tagNamespaces: TagsUtil.getAllNamespaces(state.tagNamespaces),

  };
}

const styled = withStyles(styles)(withIsMobile(ProviderCommentsAffiliationChart));
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ProviderCommentsAffiliationChart};
