import React from 'react';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
import { subDays, startOfDay, endOfDay } from 'date-fns';
import { connect } from 'react-redux';
import {
  Card,
  CardContent,
  Grid2,
  Divider,
  Typography
} from '@mui/material';
import {
  ProviderCommentsReportTable,
  ProviderCommentsReportChart,
  ProviderCommentsPerDayChart,
  ProviderCommentsTaggedPerDayChart,
  ProviderCommentsByLabel,
  ProviderCommentsOptions,
  ProviderCommentsHeadlines,
  ProviderCommentsAffiliationChart,
  ProviderCommentsSpecialtiesChart,
  ProviderCommentsCountiesChart
} from 'components';
import { PropTypes } from 'prop-types';

const styles = (theme) => ({
  container: {
    '& > *': {
      marginBottom: theme.spacing(4),
    },
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  card: {
    marginBottom: theme.spacing(3),
  },
  

  
});
const isValidDate = (d) => d instanceof Date && !isNaN(d);
const minimumStartDate = new Date(2018, 0, 1);

class ProviderCommentsReportPage extends React.Component {
  constructor(props) {
    super(props);
    const startDate = new Date(
      sessionStorage.getItem('startDate') ?? undefined,
    );
    const endDate = new Date(sessionStorage.getItem('endDate') ?? undefined);
    const encounterType = sessionStorage.getItem('encounterType');
    const filterUser = sessionStorage.getItem('filterUser');
    this.state = {
      startDate: startOfDay(
        isValidDate(startDate) ? startDate : subDays(new Date(), 30),
      ),
      endDate: endOfDay(isValidDate(endDate) ? endDate : new Date()),
      
      encounterType: (encounterType && encounterType!='All') ? encounterType*1 : 'All',
      filterUser: filterUser ? JSON.parse(filterUser) : 'All Users',
      isTable: false,
    };
  }

  render() {
    const { classes } = this.props;
    const { encounterType, filterUser, isTable } = this.state;
    const dateRange = {
      startDate: this.state.startDate,
      endDate: this.state.endDate
    };

    const sdatetimedisplay = moment(this.state.startDate).format( 'M/D/YY' );
    const edatetimedisplay = moment(this.state.endDate).format( 'M/D/YY ' );

    const sdatetime = moment(this.state.startDate).format( 'YYYY-MM-DD  HH:mm:ss.000' );
    const edatetime = moment(this.state.endDate).format( 'YYYY-MM-DD  HH:mm:ss.000' );

    var diffDays = moment(edatetime).diff(moment(sdatetime), 'days') + 1;



    
    return (

          <div className={classes.container}>
            <ProviderCommentsOptions 
              dateRange={dateRange} 
              encounterType={encounterType}
              onChangeStartDate={this.onChangeStartDate}
              onChangeEndDate={this.onChangeEndDate}
              onChangeEncounterType={this.onChangeEncounterType}
              onChangeUser={this.onChangeUser}
              filterUser={filterUser}
            />
              <Card sx={{marginTop:3}}>
                <CardContent>
              { isTable && (
                <ProviderCommentsReportTable 
                  dateRange={dateRange} 
                  encounterType={encounterType}
                  filterUser={filterUser}
                  showChart={this.showChart}
                />
              )}
              { !isTable && (
                <ProviderCommentsReportChart
                dateRange={dateRange} 
                encounterType={encounterType}
                filterUser={filterUser}
                showTable={this.showTable}
                />
              )}
                </CardContent>
              </Card>


              <Card>
                <CardContent>
                  <div className={classes.header}>
                    <Typography component='h2' variant='h6' color='primary'>
                      Outreach Trends
                    </Typography>
                    <Typography fontWeight='300' color='black'>
                      {sdatetimedisplay} to {edatetimedisplay} | Trend versus previous {diffDays} days
                    </Typography>
                  </div>

                  <ProviderCommentsHeadlines 
                      dateRange={dateRange} 
                      encounterType={encounterType}
                      filterUser={filterUser}
                  />

                <Divider />

                <Grid2 container marginTop='50px' spacing={1} columns={{ xs: 1, md: 1, lg: 12, xl: 12 }}>
                  <Grid2 marginBottom='50px' size={{ xs: 1, md: 1, lg: 4, xl: 4 }} justifyContent='center' alignItems='center'>
                  <Typography fontWeight='normal' color='black'  style={{ width: '100%', textAlign: 'center'}}>
                      Specialties of Tagged Providers
                    </Typography>
                    <ProviderCommentsSpecialtiesChart 
                      dateRange={dateRange} 
                      encounterType={encounterType}
                      filterUser={filterUser}
                    />

                  </Grid2>
                  <Grid2 size={{ xs: 1, md: 1, lg: 4, xl: 4 }} justifyContent='center' alignItems='center'>
                  <Typography fontWeight='normal' color='black'  style={{ width: '100%', textAlign: 'center'}}>
                      Affiliation of Tagged Providers
                    </Typography>
                    <ProviderCommentsAffiliationChart 
                      dateRange={dateRange} 
                      encounterType={encounterType}
                      filterUser={filterUser}
                    />
                    
                          
                  </Grid2>
                  <Grid2 marginBottom='50px' size={{ xs: 1, md: 1, lg: 4, xl: 4 }} justifyContent='center' alignItems='center'>
                  <Typography fontWeight='normal' color='black'  style={{ width: '100%', textAlign: 'center'}}>
                      Counties of Tagged Providers
                    </Typography>   
                    <ProviderCommentsCountiesChart 
                      dateRange={dateRange} 
                      encounterType={encounterType}
                      filterUser={filterUser}
                    />
                

                  </Grid2>
                </Grid2>
              
              </CardContent>
            </Card>

              <ProviderCommentsPerDayChart 
                dateRange={dateRange}
                encounterType={encounterType}
                filterUser={filterUser}
              />

            <ProviderCommentsTaggedPerDayChart 
              dateRange={dateRange} 
              filterUser={filterUser}
              encounterType={encounterType}
            />

            <ProviderCommentsByLabel
              dateRange={dateRange} 
              filterUser={filterUser}
              encounterType={encounterType}
            />

          </div>
    );
  }

  showTable = () => {
    this.setState({isTable: true});
  }

  showChart = () => {
    this.setState({isTable: false});
    this.setState({isData: true});      

  }

  onChangeUser = (newUser) => {
    sessionStorage.setItem('filterUser', JSON.stringify(newUser));
    this.setState(
        { filterUser: newUser !== 'All Users' ? newUser : 'All Users' }
    );
  };

  onChangeEncounterType = (type) => {
    sessionStorage.setItem('encounterType', type);
    this.setState({
      encounterType: type
    });
  }


  onChangeStartDate = (newDate) => {
    if (moment(newDate).isAfter(moment(minimumStartDate))) {
      sessionStorage.setItem('startDate', newDate);
      this.setState({
        startDate: startOfDay(newDate)
      });
    }
  }

  onChangeEndDate = (newDate) => {
    sessionStorage.setItem('endDate', newDate);
    this.setState({
      endDate: endOfDay(newDate)
    });
  }
}

ProviderCommentsReportPage.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
};

function mapStateToProps(state) {
  const { user } = state;

  return {
    user
  };
}

const styled = withStyles(styles)(ProviderCommentsReportPage);
const connected = connect(mapStateToProps)(styled);
export { connected as ProviderCommentsReportPage };
