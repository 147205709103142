import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import {
  TableUtil,
} from 'utils';

import {
  StoreUtil,
  DateUtil,
} from 'doctivity-shared/utils';

import {
  TableWithDialog,
  LoadingView,
  ManageClientDataDialog,
  SmallIconTextButton,
} from 'components';

import {
  queryClients,
  upsertClient,
} from 'store/actions/clientsActions';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
});

const schema = {
  type: 'object',
  required: ['name'],
  properties: {
    name: { type: 'string', title: 'Name' },
    most_recent_date: { type: 'string', format: 'date', title: 'Latest Data' },
    report_day_of_month: {
      type: 'number',
      title: 'Email Report Day of Month',
      // eslint-disable-next-line max-len
      enumNames: [
        'Disabled',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
      ],
      // eslint-disable-next-line max-len
      enum: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27, 28,
      ],
    },
    has_claims_access: { type: 'boolean', title: 'Claims License' },
    override_revenue: {
      type: 'boolean',
      title: 'Override Visit Revenue (requires upload)',
    },
    is_active: { type: 'boolean', title: 'Active' },
    unresolved_notes_report: {
      type: 'boolean',
      title: 'Send Unresolved Notes Report',
    },
  },
};

const uiSchema = {
  most_recent_date: {
    classNames: 'two-column-field',
  },
  report_day_of_month: {
    classNames: 'two-column-field',
  },
  has_claims_access: {
    classNames: 'two-column-field',
  },
  override_revenue: {
    classNames: 'two-column-field',
  },
  is_active: {
    classNames: 'two-column-field',
  },
  unresolved_notes_report: {
    classNames: 'two-column-field',
  },
};

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: 'Name',
    key: 'name',
  },
  {
    label: 'Report Day',
    key: 'report_day_of_month',
    format: (day) => day || '-',
    filter: false,
  },
  {
    label: 'Latest Data',
    key: 'most_recent_date',
    format: DateUtil.formatDateForUser,
    filter: false,
  },
  {
    label: 'Active',
    key: 'is_active',
    sortable: true,
    sortDown: false,
    format: TableUtil.boolToCheckIcon,
    style: { width: 80 },
    filter: false,
  },
];

const NEW_CLIENT = {
  is_active: true,
  has_claims_access: false,
  override_revenue: false,
};

class ClientsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        order: [
          ['name', 'ASC'],
        ],
        limit: 25,
        offset: 0,
      },

      isManageDataDialogOpen: false,
      downloadingAffiliateDataClient: {},
    };

    this.onSave = this.onSave.bind(this);
    this.onQueryChange = this.onQueryChange.bind(this);
  }

  componentDidMount() {
    const {
      clients,
    } = this.props;

    if (StoreUtil.needsLoadNoCache(clients)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    // is object getting edited?
    if (this.props.editing !== prevProps.editing) {
      // if so and it is newr than the list, update list
      if (StoreUtil.hasSavedSinceLoad(this.props.editing, this.props.clients)) {
        this.fetchData();
      }
    }
  }

  onSave(data) {
    const {
      dispatch,
    } = this.props;
    dispatch(upsertClient(data));
  }

  onQueryChange(query) {
    this.setState({ query }, this.fetchData.bind(this));
  }

  fetchData() {
    const {
      dispatch,
    } = this.props;

    const {
      query,
    } = this.state;

    dispatch(queryClients(query));
  }

  render() {
    const {
      clients,
      editing,
      classes,
    } = this.props;

    const {
      query,
    } = this.state;

    // is a user being edited now?
    if (StoreUtil.isSaving(editing)) {
      return (<LoadingView />);
    }

    return (
      <>
        <Helmet defer={false}>
          <title>Clients</title>
        </Helmet>
        <div className={classes.container}>
          <TableWithDialog
            title='Clients'
            dialogTitle='Client'
            columns={columns}
            dialogSchema={schema}
            dialogUISchema={uiSchema}
            query={query}
            data={clients}
            onQueryChange={this.onQueryChange}
            onSave={this.onSave}
            newItem={NEW_CLIENT}
            dialogAdditionalContent={(formData) => {
              return (
                <div>
                  <SmallIconTextButton
                    icon='cloud_sync'
                    text='Manage Data' 
                    onClick={() => this.onManageData(formData)}
                  />
                </div>
              )
            }}
          />
        </div>
        <ManageClientDataDialog 
          open={this.state.isManageDataDialogOpen}
          clientId={this.state.downloadingAffiliateDataClient.clientId}
          clientName={this.state.downloadingAffiliateDataClient.clientName}
          onCancel={this.onManageDataClose}
          onClose={this.onManageDataClose}
        />
      </>
    );
  }

  onManageData = (formData) => {
    this.setState({
      isManageDataDialogOpen: true,
      downloadingAffiliateDataClient: {
        clientId: formData.id,
        clientName: formData.name,
      }
    });
  }

  onManageDataClose = () => {
    this.setState({
      isManageDataDialogOpen: false
    });
  }
}

ClientsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  clients: PropTypes.object.isRequired,
  editing: PropTypes.object,
};

function mapStateToProps(state) {
  const {
    clients,
  } = state;

  return {
    clients: StoreUtil.get(clients, StoreUtil.COMMON_TABLE),
    editing: StoreUtil.get(clients, StoreUtil.COMMON_EDIT_ITEM),
  };
}

const styled = withStyles(styles)(ClientsPage);
const connected = connect(mapStateToProps)(styled);
export { connected as ClientsPage };
